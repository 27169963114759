import { useEffect, useState } from "react";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useNavigate } from "react-router-dom";
import Loader from "../Resuables/Loader";
import {
  DropDownOptions,
  LoadCount,
  LoadMetricResponse,
  loadMetricsRequest,
} from "../../interface/LoadInterface";
import { getCarrierOptions, getCustomerOptions, getLoadMetrics } from "../../service/Api";
import { getTimestampToDate } from "../../utils/Formatting";
import { SomethingWentWrong } from "../Resuables/SomethingWentWrong";
import TimeFrame from "../Resuables/TimeFrame";
//PC_LM_69
import Select from "react-select";
import { useTheme } from "../ThemeContext";
import { Theme, TrackingStatus } from "../../enums/enums";
import { ReactSelectOptions } from "../../interface/Interface";

export const LoadMetrics = () => {
  const strokeWidth = 3;
  const gradientId = "progress-gradient";

  //PC_LM_0 Declare state variables and initialize it.
  const [loadStatusCount, setLoadStatusCount] = useState<LoadMetricResponse>({
    loadCounts: [],
    recordCount: 0,
  });
  const tmsId: number = Number(localStorage.getItem("tmsId") || 0);
  const [keyTime, setKeyFrame] = useState<string>("");
  const [carrierOptions, setCarrierOptions] = useState<ReactSelectOptions[]>([]);
  const [shipperOptions, setShipperOptions] = useState<ReactSelectOptions[]>([]);
  const [loadReq, setLoadReq] = useState<loadMetricsRequest>({
    carrierId: "",
    customerId: "",
    fromDate: "",
    toDate: "",
    tmsId: "",
  });
  const [somethingWentWrong, setSomethingWentWrong] = useState<boolean>(false);
  const [loader, isLoader] = useState<boolean>(false);
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const { themeType } = useTheme();

  const navigate = useNavigate();

  /*
    PC_LM_07 to PC_LM_12
    using the useEffect Hook to render the page
    declare a variable tmsId and store the value of companyId from localStorage 
    To call the method retrieveLoadMetrics, getCarriers, getShipper
    */
  useEffect(() => {
    let tmsId = localStorage.getItem("tmsId") || "";
    if (tmsId === "") {
      setSomethingWentWrong(true);
    } else {
      setLoadReq((prevReq) => ({ ...prevReq, tmsId: tmsId }));
      const updatedRequest = {
        ...loadReq,
        tmsId: tmsId,
      };
      Promise.all([retrieveLoadMetrics(updatedRequest), getCarriers(), getShipper()]);
    }
  }, []);

  /*
  PC_LM_13 to PC_LM_32
  Invoke the function retrieveLoadMetrics with loadRequest as paramter
  check the status code from the response.
  Using switch conditional statement to set state the variable of loadMetrics
  (Eld, driverCount, ManualCount, NotYetStarted load counts)
   */
  const retrieveLoadMetrics = async (loadRequest: loadMetricsRequest) => {
    try {
      isLoader(true);
      const response = await getLoadMetrics(loadRequest);
      if (response?.data?.code !== 200) {
        setSomethingWentWrong(true);
      } else {
        // Apply state update
        setLoadStatusCount({
          loadCounts: response?.data?.data,
          recordCount: response?.data?.record_count,
        });
      };
    } catch (error) {
      console.log("retrieveLoadMetrics error log", error)
    } finally {
      isLoader(false);
    }
  }

  /*
  PS_LM_45 to PS_LM_48
  Invoke the function getCarriers()
  to get the data for the carriers dropdown using await method
   */
  async function getCarriers() {
    try {
      //PS_GC_01
      let result = await getCarrierOptions(tmsId);
      if (result?.data?.code == 200) {
        const options = result?.data?.data.map((option: { display_text: any; code: any; }) => ({
          label: option.display_text,
          value: option.code,
        }));
        setCarrierOptions(options);
      } else {
        setCarrierOptions([]);
      }
    } catch (error) {
      console.log("Error while feching Carriers Dropdown :", error);
    }
  }

  /*
PS_LM_48 to PS_LM_49
Invoke the function getShippers()
to get the data for the shippers dropdown using await method
 */
  async function getShipper() {
    try {
      //PS_GC_01
      let result = await getCustomerOptions(tmsId);
      if (result?.data?.code == 200) {
        const options = result?.data?.data.map((option: { display_text: any; code: any; }) => ({
          label: option.display_text,
          value: option.code + '-' + option.display_text,
        }));
        setShipperOptions(options);
      } else {
        setShipperOptions([]);
      }
    } catch (error) {
      console.log("Error while feching Customers Dropdown :", error);
    }
  }

  /*
    PC_LM_33 to PC_LM_36
    Invoke the function percentageCalculation with metricCount as parameter
    calculate the percentage using the formula
    store it in a variable "result" and return it
     */
  const percentageCalculation = (metricCount: number) => {
    if (loadStatusCount.recordCount > 0) {
      let result = (metricCount / loadStatusCount.recordCount) * 100;
      return result;
    }
    return 0;
  };

  /*
  PC_LM_37 to PC_LM_44
  Invoke the function bindCircularBar with metricCount and trackingStatusId as parameter
  Declare a variable percentage to store te result and invoke the function
  percentageCalculation with metricCount as parameter
  and return the progressBar with the calculated percentages.
  */
  const bindCircularBar = (metricCount: number) => {
    let percentage = percentageCalculation(metricCount);

    return (
      <CircularProgressbarWithChildren
        value={percentage}
        strokeWidth={strokeWidth}
        styles={buildStyles({
          strokeLinecap: "round",
          pathTransitionDuration: 1.5,
          pathColor: `url(#${gradientId})`,
          trailColor: "#d6d6d6",
        })}
      >
        <div
          className="font-32 font-semibold color-black-v1"
          style={{
            position: "absolute",
            // marginTop: '100%',
            top: "100%",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // bottom:'-13px'
          }}
        >
          {percentage.toFixed(0)}%
        </div>

        <svg
          width={"100%"}
          height={"100%"}
          style={{ position: "absolute", top: 0, left: 0 }}
        >
          <defs>
            {themeType === Theme.DARK ? (
              <linearGradient id={gradientId} x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="33%" stopColor="#46CB93" />
                <stop offset="66%" stopColor="#42AE80" />
              </linearGradient>
            ) : (
              <linearGradient id={gradientId} x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="30%" stopColor="#B7356E" />
                <stop offset="33%" stopColor="#ED2D38" />
                <stop offset="88%" stopColor="#F85839" />
                <stop offset="100%" stopColor="#F9B455" />
              </linearGradient>
            )}
          </defs>
        </svg>
      </CircularProgressbarWithChildren>
    );
  };

  const handleClick = (statusId: string) => {
    navigate(`/loadmetrics/loads`, {
      state: {
        tracking_status_code: statusId,
        carrier_id: loadReq.carrierId,
        customer_id: loadReq.customerId,
        from_date: loadReq.fromDate,
        to_date: loadReq.toDate,
        carrierObj: carrierOptions,
        customerObj: shipperOptions,
        key: keyTime,
      },
    });
  };

  /*PS_LM_63 to PS_LM_66
  Invoke the function handlerClear
  Reset the value to the initial values
  set state the filterOpen state variable to false to close the popup
  Invoke the function retreiveLoadMetrics with updatedLoadReq as parameter */
  const handleClear = () => {
    const updatedLoadReq = {
      ...loadReq,
      carrierId: "",
      customerId: "",
    };
    setIsFilterOpen(false);
    setLoadReq(updatedLoadReq);
    retrieveLoadMetrics(updatedLoadReq);
  };

  /*PS_LM_67 to PS_LM_68
  Invoke the function timeFrameOnChange with startDate and endDate as parameter
  Set state the LoadReq to the fromDate and toDate. */
  const timeFrameOnChange = (
    startDate: Date | "",
    endDate: Date | "",
    key: string | undefined
  ) => {
    if (key) {
      setKeyFrame(key);
    }
    setLoadReq((prevState) => ({
      ...prevState,
      fromDate:
        startDate === ""
          ? ""
          : getTimestampToDate(startDate.toISOString(), 2) || "",
      toDate:
        endDate === ""
          ? ""
          : getTimestampToDate(endDate.toISOString(), 2) || "",
    }));
    const updatedRequest = {
      ...loadReq,
      fromDate:
        startDate === ""
          ? ""
          : getTimestampToDate(startDate.toISOString(), 2) || "",
      toDate:
        endDate === ""
          ? ""
          : getTimestampToDate(endDate.toISOString(), 2) || "",
    };
    retrieveLoadMetrics(updatedRequest);
  };
  //PC_LM_73
  const handleCarrier = (selectedOptions: any) => {

    // Assuming setLoadsFilter exists in your component
    // Update any other state based on selected options
    setLoadReq((prevData) => ({
      ...prevData,
      carrierId: selectedOptions?.value,
    }));
  };

  //PC_LM_74
  const handleCustomer = (selectedOptions: any) => {
    // Assuming setLoadsFilter exists in your component
    // Update any other state based on selected options
    setLoadReq((prevData) => ({
      ...prevData,
      customerId: selectedOptions?.value,
    }));
  };

  const bindLoadStatusCount = () => {
    return (
      loadStatusCount.loadCounts.map((load, index) => (
        <div
          className="col-lg-4 col-xl-3 col-md-6 col-sm-12"
          onClick={() => {
            handleClick(load.tracking_code);
          }}
        >
          <div className="card h-100 border-0 sd-card">
            <div className="d-flex flex-column justify-content-between">
              {load.tracking_status == TrackingStatus.ELD ?
                <h3 className="font-20 font-medium tooltip-color load-metrics-header">
                  <img
                    src="/images/eld.svg"
                    alt="eld-icon"
                    className="me-2 tracking-status-eld-img"
                  />
                  ELD
                </h3>
                : load.tracking_status == TrackingStatus.DRIVERAPP ? <h3 className="font-20 font-medium tooltip-color load-metrics-header">
                  <img
                    src="/images/driver-application.svg"
                    alt="driver-application"
                    className="me-2  tracking-status-eld-img"
                  />
                  Driver Application
                </h3> : load.tracking_status == TrackingStatus.MANUAL ? <h3 className="font-20 font-medium tooltip-color load-metrics-header">
                  <img
                    src="/images/manual.svg"
                    alt="manual-icon"
                    className="me-2 tracking-status-eld-img"
                  />
                  Manual
                </h3> : load.tracking_status == TrackingStatus.NOTSTARTED ? <h3 className="font-20 font-medium tooltip-color load-metrics-header">
                  <img
                    src="/images/yet-to-start.svg"
                    alt="not-yet-started-icon"
                    className="me-2 tracking-status-eld-img"
                  />
                  Not Yet Started
                </h3> : null}
              <div>
                <div className="text-center my-4 cust-chart">
                  {bindCircularBar(load.loads_count)}
                </div>
                <p className="font-16 font-medium mb-3 text-center color-grey-v1">
                  Loads Count:{" "}
                  <span className="font-16 font-semibold color-black-v1">
                    {load.loads_count}
                  </span>
                </p>
                <p className="font-14 font-regular mb-0 color-grey-v1 mb-4">
                  {load.tracking_status == TrackingStatus.ELD ? "Loads calculated using ELD, which is a hardware device that connects to a vehicle's engine." :
                    load.tracking_status == TrackingStatus.DRIVERAPP ? "Driver application tracking uses mobile apps on drivers devices to collect and transmit real-time task and route data." :
                      load.tracking_status == TrackingStatus.MANUAL ? "Manual tracking is done by support staff by contacting the driver and update the tracking events, status, and tracking provider." :
                        load.tracking_status == TrackingStatus.NOTSTARTED ? "These loads are scheduled shipments that haven't initiated tracking." : ""}
                </p>
              </div>
            </div>
          </div>
        </div>
      ))
    );
  };

  return (
    <>
      {loader ? <Loader /> : <></>}
      {somethingWentWrong ? (
        <SomethingWentWrong onClose={() => setSomethingWentWrong(false)} />
      ) : null}
      <div className="col-md-12 px-5 fixedhd">
        <div className="mt-5 border-0 rounded-3">
          <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap gap-3">
            <h5 className="color-black-v1 font-22 font-bold mt-2">
              Loads Metrics
            </h5>
            <div className="d-flex gap-3 w-md-100 position-relative">
              <div className="form-floating w-130px">
                {<TimeFrame onChange={timeFrameOnChange} />}
              </div>
              <button
                className={
                  isFilterOpen
                    ? "btn filter-btn border-0 custom-border-box-shadow primary-bg-color show"
                    : "btn filter-btn border-0 custom-border-box-shadow primary-bg-color"
                }
                aria-expanded="true"
                onClick={() => {
                  setIsFilterOpen(!isFilterOpen);
                }}
              >
                <img src="/images/filter-icon.svg" alt="filter-icon" />
              </button>
              <div
                className={
                  isFilterOpen
                    ? "dropdown-menu dropdown-menu-end border-0 shadow p-3 custom-filter-width primary-bg-color top-80 show"
                    : "dropdown-menu dropdown-menu-end border-0 shadow p-3 custom-filter-width secondary-bg-color"
                }
                aria-labelledby="dropdownMenuLink"
              >
                {isFilterOpen && (
                  <div className="d-flex justify-content-between align-items-center mb-4">
                    <h5 className="font-bold font-20 mb-0 custom-header-color form-text-color">
                      Advanced Filter
                    </h5>
                    <span>
                      <img
                        className="cursor-pointer left-arrow"
                        src="/images/cross-icon-black.svg"
                        alt="close"
                        onClick={() => {
                          setIsFilterOpen(!isFilterOpen);
                        }}
                      />
                    </span>{" "}
                  </div>
                )}
                <div className="row">
                  <div className="col-md-12 mb-4">
                    <label
                      className="form-label font-14 font-semibold label-color form-text-color"
                      htmlFor="Status"
                    >
                      Carrier Name
                    </label>
                    {/*PC_LM_70*/}
                    <Select
                      className="dropdown custom-form-input"
                      name="carrier"
                      maxMenuHeight={150}
                      placeholder="Select"
                      value={carrierOptions && loadReq.carrierId
                        ? carrierOptions.find(option => option.value === loadReq.carrierId) || null
                        : null
                      }
                      options={carrierOptions || []}
                      onChange={handleCarrier}
                      isClearable
                    />
                  </div>
                  <div className="col-md-12 mb-4">
                    <label
                      className="form-label font-14 font-semibold label-color form-text-color"
                      htmlFor="connectionstatus"
                    >
                      Customer Name
                    </label>
                    <Select
                      className="dropdown custom-form-input"
                      name="customer"
                      maxMenuHeight={150}
                      placeholder="Select"
                      value={shipperOptions && loadReq.customerId
                        ? shipperOptions.find(option => option.value === loadReq.customerId) || null
                        : null
                      }
                      options={shipperOptions || []}
                      onChange={handleCustomer}
                      isClearable
                    />
                  </div>
                  <div className="col-md-12">
                    <div className="d-flex justify-content-end align-items-center py-3">
                      <button
                        className="btn text-nowrap w-md-100 font-medium color-black-v1 font-14 d-flex gap-2 justify-content-center align-items-center py-2 me-3 px-4 form-text-color"
                        onClick={() => {
                          setIsFilterOpen(!isFilterOpen);
                          handleClear();
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        disabled={
                          !loadReq.carrierId &&
                          !loadReq.customerId &&
                          !loadReq.fromDate &&
                          !loadReq.toDate
                        }
                        className="btn primary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center px-4"
                        onClick={() => {
                          setIsFilterOpen(!isFilterOpen);
                          retrieveLoadMetrics(loadReq);
                        }}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row row-cols-1 row-cols-md-3 g-4">
            {bindLoadStatusCount()}
          </div>
          <div className="text-end my-4 view-arrow">
            <a
              className="text-decoration-none link-color font-20 font-medium cursor-pointer"
              onClick={() => {
                navigate(`/loadmetrics/loads`);
              }}
            >
              View All Loads
              <img src="/images/right-arrow.svg" alt="arrow" className="ms-2" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
