import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { EventsStatus, eventType } from "../../../enums/enums";
import { CancelTracking, Events, AdvancedLoadDetails, DropDownOptions, EventResponse, StopLocations } from "../../../interface/LoadInterface";
import { cancelTrackingAPI, getLoadDetail, getStatus } from "../../../service/Api";
import BreadCrumbs from "../../Resuables/BreadCrumbs";
import EventPopup from "../../Resuables/EventPopUp";
import Loader from "../../Resuables/Loader";
import { SomethingWentWrong } from "../../Resuables/SomethingWentWrong";
import CarrierDetails from "../../CarrierDetails/CarrierDetails";
import CustomValues from "./CustomValues";
import CustomerDetail from "./CustomerDetails";
import DriverDetails from "./DriverDetails";
import DriverHistory from './DriverHistory';
import DriverDetailPopup from './DriverDetailPopup'
import EditEquipmentDetails from "./EditEquipmentDetails";
import EquipmentDetail from "./EquipmentDetails";
import Marker from "./Marker";
import ThirdPartyDetails from "./ThirdPartyDetails";
import ReferenceNumbers from "./ReferenceNumber";
import Shippment from "./Shippment";
import TrackingEventLogs from "./TrackingEventLogs";
import TrackingEvents from "./TrackingEvents";
import TrackingTimeLine from "./TrackingTimeLine";
import { SuccessToast } from "../../Resuables/SuccessToast";
import ConfirmationPopUp from "../../Resuables/ConfirmationPopUp";
import { getmoveTypeDescription } from "../../../utils/utils";
import AlertComponent from "../../Resuables/AlertComponent";


// Define the functional component
function LoadDrilldown() {

    // This block is PSLL_01 - Initialization
    const navigate = useNavigate();
    const location = useLocation();


    // This block handles PSLL_01 (sequence number SQ_LL_1.0) and PSLL_02 (SQ_LL_1.1)
    const [loadDetails, setLoadDetails] = useState<AdvancedLoadDetails>({} as AdvancedLoadDetails);
    const [loader, setLoader] = useState(false);
    const [isNewMessage, setNewMessage] = useState(true);
    const [errorPopUp, setErrorPopUp] = useState(false);
    //PS_DD_02 Declaring state variables to store dropdown values.
    const [statusDropDowns, setStatusDropDown] = useState<DropDownOptions[]>([]);
    const [stopLocations, setShippmentPayload] = useState<StopLocations[]>([])
    const [trackingEvent, setTrackingEvent] = useState<EventResponse[]>([]);
    const [driverhistory, setDriverhistory] = useState<boolean>(false);
    const [eventDetails, setEventDetails] = useState<Events>({
        loadId: 0,
        tmsId: 0,
        trackingeventId: 0,
        eventType: '',
        event: '',
        eventCode: '',
        inTime: '',
        outTime: '',
        notes: '',
        isManual: true,
        reasonCode: "",
        reasonForDelay: '',
        addressLine1: '',
        city: '',
        state: '',
        onTime: true
    })
    const [eventPopup, setEventPopup] = useState<boolean>(false);
    const breadcrumbData = [
        { title: 'Load Metrics', isActive: true, path: '/loadmetrics' },
        { title: 'Loads', isActive: true, path: '/loadmetrics/loads', state: location.state },
        { title: `${loadDetails?.load_details?.load_number || 0}`, isActive: false, path: '' },
    ];
    const { loadID } = useParams();
    const loadId: number = parseInt(loadID || '', 10);
    const tmsId: number = Number(localStorage.getItem("tmsId") || 0);
    const [toastMessage, setToastMessage] = useState("");
    let [openEquipment, openEquipmentState] = useState<boolean>(false);
    //PS_22
    const [confirmPopUp, setconfirmPopUp] = useState<Boolean>(false);
    const [alertMessage, setAlertMessage] = useState<string>("");
    //PS_21,22
    const trackingDescription = loadDetails?.load_details?.tracking_description;
    let [driverDetailOpen, setOpenDriverDetail] = useState<boolean>(false);

    // PSLL_03 (SQ_LL_1.2), PSLL_04 (SQ_LL_1.3), PSLL_05 (SQ_LL_1.4)
    useEffect(() => {
        Promise.all([getStatusOptions(), getLoads()])
    }, []);
    useEffect(() => {
        if (toastMessage != "") {
            const timer = setTimeout(() => {
                setToastMessage(""); // Hide the toast message after 10 seconds
            }, 3000);

            return () => clearTimeout(timer); // Clear the timer on component unmount or when openToastMessage changes
        }
    }, [toastMessage]);
    //PS_FP_1.34-PS_FP_1.36
    const openToast = (messages: string) => {
        setToastMessage(messages);
    }
    const getLoads = async () => {
        try {
            // PSLL_06 (SQ_LL_1.5)
            setLoader(true);
            // PSLL_07 (SQ_LL_1.6) to PSLL_11 (SQ_LL_1.10)
            const result = await getLoadDetail(tmsId, loadId);
            // PSLL_15 (SQ_LL_1.14)
            if (result?.data?.code === 200) {
                setLoadDetails(result?.data?.data);
                setShippmentPayload(result?.data?.data?.stop_locations || [])
                setTrackingEvent(result?.data?.data?.event_info || [])
            } else {
                // PSLL_16 (SQ_LL_1.15)
                setLoadDetails({} as AdvancedLoadDetails);
                setErrorPopUp(true);
                setShippmentPayload([])
                setTrackingEvent([])
                setErrorPopUp(true);

            }
        } catch (error) {
            // PSLL_17 (SQ_LL_1.16), PSLL_18 (SQ_LL_1.17)
            setLoadDetails({} as AdvancedLoadDetails);
            setErrorPopUp(true);
            setShippmentPayload([])
            setTrackingEvent([])
        } finally {
            // PSLL_19 (SQ_LL_1.18)
            setLoader(false);
        }
    };
    const onError = () => {
        setErrorPopUp(true)
    }
    const openDriver = () => {
        setDriverhistory(true)
    }
    const closeDriver = () => {
        setDriverhistory(false)
    }

    const openEquipmentEdit = () => {
        openEquipmentState(true)
    }
    const openDriverDetail = () => {
        setOpenDriverDetail(!driverDetailOpen)
    }
    const closeNewMessage = () => {
        setNewMessage(false)
    }

    //PS_DD_05 - 19 Get status dropdown
    async function getStatusOptions() {
        try {
            let result = await getStatus();
            if (result?.data?.code == 200) {
                setStatusDropDown(result?.data?.data)
            } else {
                setStatusDropDown([])
            }
        } catch (error) {
            console.log("Error while feching status Dropdown :", error)
        }

    }

    const handleEventPop = (event: string, eventCode: string) => {
        const tmsID = localStorage.getItem('tmsId');
        const events: Events = {
            loadId: loadId || 0,
            tmsId: tmsID ? parseInt(tmsID) : 0,
            eventType: eventType.trackingEvent,
            event: event,
            eventCode: eventCode,
            stopId: loadDetails?.load_details?.current_stop_id,
            inTime: '',
            outTime: '',
            notes: '',
            reasonCode: '',
            reasonForDelay: '',
            addressLine1: '',
            addressLine2: '',
            isManual: true,
            city: '',
            state: '',
            onTime: true
        }
        setEventDetails(events)
        setEventPopup(true);

    }
    const onClose = () => {
        setEventPopup(false);
    }

    async function cancelTracking() {
        try {
            setconfirmPopUp(false);
            setLoader(true);
            let cancelTracking: CancelTracking = {
                etm_load_id: loadDetails?.load_details.load_id,
                tms_load_number: loadDetails?.load_details?.load_number,
            }
            let response = await cancelTrackingAPI(cancelTracking);
            if (response?.data?.code == 200) {
                setToastMessage("Tracking has been cancelled Successfully")
                getLoads()
            } else {
                setErrorPopUp(true);
            }

        } catch (error) {
            console.log(error, "Error in CancelTracking");
            setErrorPopUp(true);
        } finally {
            setLoader(false);
        }
    }

    //PSP_23 - PSP_25
    const handleBackButtonClick = (event: any) => {
        navigate('/loadmetrics/loads', {
            state: location.state
        });
    };

    function errorMessage(message: string) {
        setAlertMessage(message)
        getLoads()
    };
    //PSP_21 To view Move type's full form on hover
    return (
        <>
            {
                loader && <Loader />
            }
            {
                eventPopup && <EventPopup events={eventDetails} openLoader={() => setLoader(true)} closeLoader={() => setLoader(false)} onError={onError} updateEvent={false} onClose={onClose} openToast={openToast} getLoads={getLoads} />
            }
            {toastMessage ?
                <div>
                    {SuccessToast(toastMessage)}
                </div> : <></>
            }
            {alertMessage ?
                <AlertComponent errHeading="Alert" onClose={() => { setAlertMessage("") }} err={alertMessage} />
                : <></>}
            {openEquipment && loadDetails?.equipment_detail ?

                <EditEquipmentDetails loadId={loadId || 0} openLoader={() => setLoader(true)} closeLoader={() => setLoader(false)} onClose={() => openEquipmentState(false)} onError={onError} getLoads={getLoads} openToast={openToast} equipment={loadDetails?.equipment_detail} /> : <></>
            }
            {driverDetailOpen && loadDetails?.driver_detail ?
                <DriverDetailPopup getLoads={getLoads} openToast={openToast} loadId={loadId} driverDetail={loadDetails?.driver_detail} openPopup={openDriverDetail} openLoader={() => setLoader(true)} closeLoader={() => setLoader(false)} onError={onError} /> : <></>
            }
            {driverhistory ?
                <div className="modal-overlay">
                    <div className="modal fade show" id="driverhistory" tabIndex={-1} aria-labelledby="exampleModalLabel" style={{ display: 'block', paddingLeft: '0px' }} aria-modal="true" role="dialog">
                        {<DriverHistory loadId={loadId || 0} onClose={closeDriver} onError={onError} />}
                    </div>
                </div> : <></>
            }
            {errorPopUp ? <SomethingWentWrong onClose={() => setErrorPopUp(false)} /> : null}
            <div className={loadDetails?.load_details?.is_read == false && isNewMessage ? "container-fluid message-received" : "container-fluid"}>

                <div className="col-md-12 fixedhd">

                    <div className="px-2 py-4 p-md-4 mb-3 border-0 rounded-3">
                        <BreadCrumbs data={breadcrumbData} />
                        <div className="d-lg-flex align-items-center justify-content-between mb-4 ">
                            <div className="d-flex align-items-center mb-3 mb-lg-0">
                                <span className="me-2 d-inline-flex align-items-center" onClick={(e => handleBackButtonClick(e))} style={{ cursor: "pointer" }}> <img src={"/images/back-icon.svg"} alt="back-icon" className="back-icon" /></span>
                                <h5 className="color-black-v1 font-22 mb-3 mb-lg-0 font-bold primary-text-color text-nowrap">
                                    Loads Details - {loadDetails?.load_details?.load_number}
                                </h5>
                            </div>
                            <div className="d-flex gap-3 flex-wrap">
                                <div className="dropdown d-flex justify-content-center">
                                    <button id="end-point-config" className="btn button-dropdown d-flex justify-content-center"
                                        type="button" data-bs-toggle="dropdown" aria-expanded="true"
                                        disabled={stopLocations?.length <= 1}
                                    >
                                        <div
                                            className="d-flex justify-content-between align-items-center font-14 font-regular primary-text-color gap-2">
                                            {loadDetails?.load_details?.etm_load_status}<img src="/images/profile-arrow.svg" className="dd-arrow" alt="dropdown-icon" /></div>
                                    </button>
                                    <ul
                                        className="dropdown-menu border-0 shadow-sm p-2 secondary-bg-color"
                                        data-popper-placement="bottom-start"
                                        style={{
                                            position: "absolute",
                                            inset: "0px auto auto 0px",
                                            margin: "0px",
                                            transform: "translate(-86px, 20px)"
                                        }}
                                    >
                                        {statusDropDowns.filter(stop => stop.display_text !== EventsStatus.Cancel && stop.display_text !== EventsStatus.MarkAsDelivered && stop.display_text !== EventsStatus.NeedStaffAttention && stop.display_text !== EventsStatus.NotStartedYet && stop.display_text !== EventsStatus.DepartedStop).map((stop, index) => (
                                            <li
                                                key={index}
                                                className="font-14 font-medium cursor-pointer"
                                                onClick={() => handleEventPop(stop.display_text, stop.code)}
                                            ><a className="dropdown-item py-2 custom-dropdown-li primary-text-color">
                                                    {stop.display_text}
                                                </a>
                                            </li>
                                        ))}
                                        <li className="font-14 font-medium red-legend-color"><a
                                            className="dropdown-item py-2 custom-dropdown-li red-legend-color ms-1 cursor-pointer" onClick={() => setconfirmPopUp(true)}
                                        >Cancel Tracking</a></li>
                                    </ul>
                                </div>
                                {confirmPopUp &&
                                    <ConfirmationPopUp heading="Cancel Tracking" content="Are you sure, you want to cancel tracking ?" onClose={() => { setconfirmPopUp(false) }} oKClick={() => { cancelTracking() }}></ConfirmationPopUp>}
                                <div data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title={loadDetails?.load_details?.move_type ? `${getmoveTypeDescription(loadDetails?.load_details?.move_type.toUpperCase())}` : ""}
                                    className="button-dropdown d-flex justify-content-between align-items-center font-14 font-regular primary-text-color">
                                    Move Type: <span className="font-14 font-semibold primary-text-color lh-1 ms-1">{loadDetails?.load_details?.move_type || '-'}</span>
                                </div>
                            </div>
                        </div>
                        {trackingDescription ?
                            <div className={`my-3 warning-box d-flex align-items-center p-2 ${loadDetails?.load_details?.is_message_flagged
                                ? "" : "blue-warning-box"}`}>
                                <span className="ms-3">
                                    {loadDetails?.load_details.is_message_flagged ?
                                        <img src="/images/warning-icon.svg" alt="warning-icon" /> :
                                        <img src="/images/tooltip-icon.svg" alt="tooltip-icon" />}
                                </span>
                                <p className="font-14 font-regular primary-text-color ms-2">{trackingDescription}</p>
                            </div> : <></>}
                        <div className="row">
                            <TrackingTimeLine timeLine={loadDetails?.tracking_time_line} />
                            <CarrierDetails carrier={loadDetails?.carrier_details} />
                        </div>
                        <div className="row">
                            <div className="col-12 col-xl-6 mb-3">
                                <Shippment shippmentPayload={stopLocations} trackingEvents={trackingEvent} loadId={loadId} currentStopId={loadDetails?.load_details?.current_stop_id} openToast={openToast} onError={onError} openLoader={() => setLoader(true)} closeLoader={() => setLoader(false)} getLoads={getLoads} />
                                <Marker stops={loadDetails?.stop_locations} />
                            </div>
                            <div className="col-12 col-xl-6 mb-3">
                                <div className="row">
                                    <DriverDetails driver={loadDetails?.driver_detail} openDriver={openDriver} loadId={loadDetails?.load_details?.load_id} tmsLoadNumber={loadDetails?.load_details?.load_number} onError={onError} openPopup={openDriverDetail} vendorLoadId={loadDetails?.load_details?.vendor_load_id || ""} closeNewMessage={closeNewMessage} alertMessage={errorMessage} />
                                    <EquipmentDetail equipment={loadDetails?.equipment_detail} loadId={loadDetails?.load_details?.load_id} openPopup={openEquipmentEdit} />
                                </div>
                                <TrackingEvents trackingEvents={trackingEvent} onError={onError} openToast={openToast} alertMessage={errorMessage} loadId={loadId} getLoads={getLoads} openLoader={() => setLoader(true)} closeLoader={() => setLoader(false)} vendorId={loadDetails?.load_details?.tracking_provider} vendorLoadId={loadDetails?.load_details?.vendor_load_id || ""} />
                                <TrackingEventLogs logs={loadDetails?.tracking_time_line} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-lg-6 mb-3">
                                <div className="row">
                                    <CustomerDetail CustomerDetail={loadDetails?.customer_detail} />
                                    <ThirdPartyDetails tmsDetail={loadDetails?.tms_details} />
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 mb-3">
                                <div className="row">
                                    <CustomValues customValues={loadDetails?.custom_values} />
                                    <ReferenceNumbers referenceNumber={loadDetails?.load_details?.load_refernce_numbers} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="cust-overlay">
                    <div className="loader-image">
                    </div>
                </div>
            </div>
        </>
    )
}

export default LoadDrilldown;