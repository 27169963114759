import { EncryptStorage } from 'encrypt-storage';
import { MoveType, Theme } from './enums/enums';

export const encryptStorage = new EncryptStorage("REACT_APP_SECRET_NAME", {
     stateManagementUse: true,
     storageType: 'localStorage',
});

export type ThemeVariables = Record<string, string>;

const darkTheme: ThemeVariables = {
     "--primary-color": "#46CB93",
     "--secondary-color": "#3F3F3F",
     "--shadow-theme": "#42AE80",
     "--link-color": "#5C88CA",
     "--top-nav-text-color": "#8A8A8A",
     "--form-label-color": "#EEEEEE",
     "--place-holder-color": "#7B7B7B",
     "--table-text-color": "#939496",
     "--secondary-bg-color": "#333333",
     "--primary-gredient-bg": "linear-gradient(to left, #46CB93, #42AE80)",
     "--primary-gredient-bg-hover": "linear-gradient(to left, #42AE80,#46CB93)",
     "--tool-bg": "#646464",
     "--tooltip-color": "#A4A4A4",
     "--tab-color": "#44C18C",
     "--form-field-border": "0.75px solid #464747",
     "--primary-bg-color": "#2D2D2D",
     "--primary-border-color": "1px solid #424242",
     "--primary-header-color": "#EEEEEE",
     "--secondary-header-color": "#EEEEEE",
     "--secondary-text-color": "#8A8A8A",
     "--grey-header": "#F2F2F2",
     "--gredient-text-color": "linear-gradient(to left, #46CB93, #42AE80)",
     "--table-header-bg": "#393939",
     "--table-head-text-color": "#FEFEFE",
     "--table-body-text-color": "#EEEEEE",
     "--table-body-bg-color": "#2D2D2D",
     "--table-row-border-color": "#353333",
     "--color-black-v1":"#EEEEEE",
     "--toast-bg-color": "#242C32",
     "--toggle-defualt-bg": "#A0A0A0",
     "--body-bg-grey": "#242121",
     "--body-bg": "#242121",
     "--required-color":"#ff5d5d"
 }; 

const lightTheme: ThemeVariables = {
     "--primary-color": "",
     "--secondary-color": "",
     "--shadow-theme": "",
     "--link-color": "",
     "--top-nav-text-color": "",
     "--form-label-color": "",
     "--place-holder-color": "",
     "--table-text-color": "",
     "--secondary-bg-color": "",
     "--primary-gredient-bg": "",
     "--primary-gredient-bg-hover": "",
     "--tool-bg": "",
     "--tooltip-color": "",
     "--tab-color": "",
     "--form-field-border": "",
     "--primary-bg-color": "",
     "--primary-border-color": "",
     "--primary-header-color": "",
     "--secondary-header-color": "",
     "--secondary-text-color": "",
     "--grey-header": "",
     "--gredient-text-color": "",
     "--table-header-bg": "",
     "--table-head-text-color": "",
     "--table-body-text-color": "",
     "--table-body-bg-color": "",
     "--table-row-border-color": "",
     "--color-black-v1": "",
     "--toast-bg-color": "",
     "--toggle-defualt-bg": "",
     "--body-bg-grey": "",
     "--body-bg": "",
     "--required-color": ""
}

export const THEMES: Record<Theme, ThemeVariables> = {
     "DARK": darkTheme,
     "LIGHT": lightTheme
}; 

export const nightModeStyle = {
     styles: [
          { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
          { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
          { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
          {
               featureType: "administrative.locality",
               elementType: "labels.text.fill",
               stylers: [{ color: "#d59563" }]
          },
          {
               featureType: "poi",
               elementType: "labels.text.fill",
               stylers: [{ color: "#d59563" }]
          },
          {
               featureType: "poi.park",
               elementType: "geometry",
               stylers: [{ color: "#263c3f" }]
          },
          {
               featureType: "poi.park",
               elementType: "labels.text.fill",
               stylers: [{ color: "#6b9a76" }]
          },
          {
               featureType: "road",
               elementType: "geometry",
               stylers: [{ color: "#38414e" }]
          },
          {
               featureType: "road",
               elementType: "geometry.stroke",
               stylers: [{ color: "#212a37" }]
          },
          {
               featureType: "road",
               elementType: "labels.text.fill",
               stylers: [{ color: "#9ca5b3" }]
          },
          {
               featureType: "road.highway",
               elementType: "geometry",
               stylers: [{ color: "#746855" }]
          },
          {
               featureType: "road.highway",
               elementType: "geometry.stroke",
               stylers: [{ color: "#1f2835" }]
          },
          {
               featureType: "road.highway",
               elementType: "labels.text.fill",
               stylers: [{ color: "#f3d19c" }]
          },
          {
               featureType: "transit",
               elementType: "geometry",
               stylers: [{ color: "#2f3948" }]
          },
          {
               featureType: "transit.station",
               elementType: "labels.text.fill",
               stylers: [{ color: "#d59563" }]
          },
          {
               featureType: "water",
               elementType: "geometry",
               stylers: [{ color: "#17263c" }]
          },
          {
               featureType: "water",
               elementType: "labels.text.fill",
               stylers: [{ color: "#515c6d" }]
          },
          {
               featureType: "water",
               elementType: "labels.text.stroke",
               stylers: [{ color: "#17263c" }]
          }
     ]
};

export const moveTypeDescriptions: Record<string, string> = {
     [MoveType.TL]: "Truck Load",
     [MoveType.LTL]: "Less than Truckload"
 };