//PC_SI_01-PC_SI_02 - Importing necessary packages
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import TMSDetails from "../components/TMSProcessing/TMSDetails";
import TMSCreation from "../components/TMSProcessing/TMSCreation";
import { AuthPageLayout } from "../components/AuthPageLayout";
import { ForgotPasswordComponent } from "../components/UserOnboarding/ForgotPassword";
import { HeaderComponent } from "../components/Header";
import ManageUsers from "../components/ManageUsers/ManageUsers";
import { RequireAuth } from "../components/UserOnboarding/RequireAuth";
import { ResetPasswordComponent } from "../components/UserOnboarding/ResetPasswordComponent";
import { ResetSuccessful } from "../components/UserOnboarding/ResetSuccessful";
import PageNotFound from "../components/Resuables/PageNotFound";
import { SigninComponent } from "../components/UserOnboarding/SigninComponent";
import { Verifications } from "../components/UserOnboarding/Verification";
import { RoleEnum, Theme } from "../enums/enums";
import { SignupComponent } from "../components/UserOnboarding/SignupComponent";
import Profile from "../components/TMSProcessing/Profile";
import AlertComponent from "../components/Resuables/AlertComponent";
import { useTheme } from "../components/ThemeContext";
import LoadInformation from "../components/LoadsDetails/LoadInformation";
import LoadDrilldown from "../components/LoadsDetails/LoadLevelDrilldown/LoadDrilldown";
import { LoadMetrics } from "../components/LoadsDetails/LoadMetrics";
import Reports from "../components/TMSReports/Reports";
import CarrierInformation from "../components/CarrierDetails/CarriersInformation"

export const AppRoutes = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const authPaths = ["/", "/admin", "/etm", "/resetPassword", "/forgotPassword", "/verification", "/resetSuccessful", "/signup"]

  const { themeType, changeTheme } = useTheme();
  return (
    <>
      <div className={themeType === Theme.DARK ? "dark-theme body-bg-grey" : ""}>
        {authPaths.includes(location.pathname) ? (
          <Routes>
            <Route
              path="/"
              element={
                <AuthPageLayout>
                  <SigninComponent showLoader={() => { }} hideLoader={() => { }} />
                </AuthPageLayout>
              }
            />
            <Route
              path="/signup"
              element={
                <AuthPageLayout>
                  <SignupComponent showLoader={() => { }} hideLoader={() => { }} />
                </AuthPageLayout>
              }
            />
            <Route
              path="/admin"
              element={
                <AuthPageLayout>
                  <SigninComponent showLoader={() => { }} hideLoader={() => { }} />
                </AuthPageLayout>
              }
            />
            <Route
              path="/etm"
              element={
                <AuthPageLayout>
                  <SigninComponent showLoader={() => { }} hideLoader={() => { }} />
                </AuthPageLayout>
              }
            />
            <Route
              path="/resetPassword"
              element={
                <AuthPageLayout>
                  <ResetPasswordComponent showLoader={() => { }} hideLoader={() => { }} />
                </AuthPageLayout>
              }
            />
            <Route
              path="/forgotPassword"
              element={
                <AuthPageLayout>
                  <ForgotPasswordComponent showLoader={() => { }} hideLoader={() => { }} />
                </AuthPageLayout>
              }
            />
            <Route
              path="/verification"
              element={
                <AuthPageLayout>
                  <Verifications showLoader={() => { }} hideLoader={() => { }} />
                </AuthPageLayout>
              }
            />
            <Route
              path="/resetSuccessful"
              element={
                <AuthPageLayout>
                  <ResetSuccessful />
                </AuthPageLayout>
              }
            />
          </Routes>

        ) : (
          <>
            {localStorage.getItem("accessToken") ? <HeaderComponent /> : null}
            <Routes>
              <Route path="*" element={<PageNotFound />} />
              <Route element={<RequireAuth allowedRoles={[RoleEnum.ETM_ADMIN, RoleEnum.THIRD_PARTY_ADMIN, RoleEnum.ETM_STAFF, RoleEnum.THIRD_PARTY_STAFF]}></RequireAuth>}>
                <Route path="/loadmetrics" element={<LoadMetrics />} />
                <Route path="/loadmetrics/loads" element={<LoadInformation />} />
                <Route path="/reports" element={<Reports />} />
                <Route path="/loadmetrics/loads/:loadID" element={<LoadDrilldown />} />
              </Route>
              <Route element={<RequireAuth allowedRoles={[RoleEnum.ETM_ADMIN, RoleEnum.THIRD_PARTY_ADMIN, RoleEnum.THIRD_PARTY_STAFF]}></RequireAuth>}>
                <Route path="/carrier-information" element={<CarrierInformation />} />
              </Route>
              <Route element={<RequireAuth allowedRoles={[RoleEnum.ETM_ADMIN]}></RequireAuth>}>
                <Route path="/tms-creation" element={<TMSCreation />} />
                <Route path="/tms-details" element={<TMSDetails />} />
              </Route>

              {/* PC_SI_74 */}
              <Route element={<RequireAuth allowedRoles={[RoleEnum.THIRD_PARTY_ADMIN, RoleEnum.ETM_ADMIN, RoleEnum.THIRD_PARTY_STAFF, RoleEnum.ETM_STAFF]}></RequireAuth>}>
                <Route path="/profile" element={<Profile />} />
              </Route>

              <Route element={<RequireAuth allowedRoles={[RoleEnum.THIRD_PARTY_ADMIN, RoleEnum.ETM_ADMIN]}></RequireAuth>}>
                <Route path="/manageusers" element={<ManageUsers />} />
              </Route>

            </Routes>
            {
              (localStorage.getItem("isConfigurationComplete") == "false") && (location.pathname != "/profile") ?
                <div>
                  <AlertComponent errHeading={'Alert'}
                    err={'Configuration details must be filled to enable the load tracking. Update the configurations to proceed further with tracking loads.'}
                    onClose={() => {
                      navigate('/profile');
                    }}></AlertComponent>
                </div> : <></>}
          </>
        )}
      </div>
    </>
  );
};
