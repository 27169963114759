import {
    addDays,
    addMonths,
    addWeeks,
    addYears,
    endOfDay,
    endOfMonth,
    endOfWeek,
    endOfYear,
    startOfDay,
    startOfMonth,
    startOfWeek,
    startOfYear,
    subDays,
    subMonths,
    subWeeks,
    subYears,
} from 'date-fns';
import React, { useState } from 'react';

interface TimeFrameProps {

    onChange: (startDate: Date | "", endDate: Date | "", key : string) => void;
    selectedValue ?:string ;
}
//
const timeFrameOptions = [
    { label: 'Yesterday', value: 'yesterday' },
    { label: 'Today', value: 'today' },
    { label: 'Tomorrow', value: 'nextDay' },
    { label: 'Last Week', value: 'lastWeek' },
    { label: 'This Week', value: 'thisWeek' },
    { label: 'Next Week', value: 'nextWeek' },
    { label: 'Last Month', value: 'lastMonth' },
    { label: 'This Month', value: 'thisMonth' },
    { label: 'Next Month', value: 'nextMonth' },
    { label: 'Last Year', value: 'lastYear' },
    { label: 'This Year', value: 'thisYear' },
    { label: 'Next Year', value: 'nextYear' },
    { label: 'Last 3 Months', value: 'last3Months' },
    { label: 'Last 6 Months', value: 'last6Months' },
    { label: 'Last 12 Months', value: 'last12Months' },
];

const timeFrameMap: { [key: string]: { start: () => Date; end: () => Date } } = {
    yesterday: {
        start: () => startOfDay(subDays(new Date(), 2)),
        end: () => endOfDay(new Date()), // to include today
    },
    today: {
        start: () => startOfDay(subDays(new Date(), 1)),
        end: () => endOfDay(addDays(new Date(), 1)),
    },
    nextDay: {
        start: () => startOfDay(new Date()),
        end: () => endOfDay(addDays(new Date(), 2)),
    },
    lastWeek: {
        start: () => startOfDay(subDays(startOfWeek(subWeeks(new Date(), 1)), 1)),
        end: () => endOfDay(addDays(endOfWeek(subWeeks(new Date(), 1)), 1)),
    },
    thisWeek: {
        start: () => startOfDay(subDays(startOfWeek(new Date()), 1)),
        end: () => endOfDay(addDays(endOfWeek(new Date()), 1)),
    },
    nextWeek: {
        start: () => startOfDay(subDays(startOfWeek(addWeeks(new Date(), 1)), 1)),
        end: () => endOfDay(addDays(endOfWeek(addWeeks(new Date(), 1)), 1)),
    },
    lastMonth: {
        start: () => startOfDay(subDays(startOfMonth(subMonths(new Date(), 1)), 1)),
        end: () => endOfDay(addDays(endOfMonth(subMonths(new Date(), 1)), 1)),
    },
    thisMonth: {
        start: () => startOfDay(subDays(startOfMonth(new Date()), 1)),
        end: () => endOfDay(addDays(endOfMonth(new Date()), 1)),
    },
    nextMonth: {
        start: () => startOfDay(subDays(startOfMonth(addMonths(new Date(), 1)), 1)),
        end: () => endOfDay(addDays(endOfMonth(addMonths(new Date(), 1)), 1)),
    },
    lastYear: {
        start: () => startOfDay(subDays(startOfYear(subYears(new Date(), 1)), 1)),
        end: () => endOfDay(addDays(endOfYear(subYears(new Date(), 1)), 1)),
    },
    thisYear: {
        start: () => startOfDay(subDays(startOfYear(new Date()), 1)),
        end: () => endOfDay(addDays(endOfYear(new Date()), 1)),
    },
    nextYear: {
        start: () => startOfDay(subDays(startOfYear(addYears(new Date(), 1)), 1)),
        end: () => endOfDay(addDays(endOfYear(addYears(new Date(), 1)), 1)),
    },
    last3Months: {
        start: () => startOfDay(subDays(startOfMonth(subMonths(new Date(), 2)), 1)),
        end: () => endOfDay(addDays(endOfMonth(new Date()), 1)),
    },
    last6Months: {
        start: () => startOfDay(subDays(startOfMonth(subMonths(new Date(), 5)), 1)),
        end: () => endOfDay(addDays(endOfMonth(new Date()), 1)),
    },
    last12Months: {
        start: () => startOfDay(subDays(startOfMonth(subMonths(new Date(), 11)), 1)),
        end: () => endOfDay(addDays(endOfMonth(new Date()), 1)),
    },
};
const TimeFrame: React.FC<TimeFrameProps> = ({ onChange,selectedValue}) => {
    const [selectedOption, setSelectedOption] = useState(selectedValue ||'');
    const handleOptionClick = (option: string) => {
        setSelectedOption(option);
        if (option === '') {
            // If empty string is selected, pass empty strings for both startDate and endDate
            onChange("","","");
        } else {
            const timeFrame = timeFrameMap[option];
            if (timeFrame) {
                const startDate = timeFrame.start();
                const endDate = timeFrame.end();
                const key = option;
                onChange(startDate, endDate, key);
            }
        }
    };
    return (
        <>
            <button className="custom-form-input py-0 w-125px top-nav-label-color custom-border-box-shadow primary-bg-color time-frame-input"
                data-bs-toggle="dropdown">
                <div className="d-flex justify-content-between position-relative">
                    <div>
                        <span className="mb-0 d-block pt-1 font-10 font-regular top-nav-label-color cust-opacity text-start">Time Frame</span>
                        <span className="mb-0 text-start ps-1 d-block font-regular font-11 primary-header-color">
                            {selectedOption ? timeFrameOptions.find(option => option.value === selectedOption)?.label : 'All'}
                        </span>
                    </div>
                    <img src="/images/select-arrow-icon.svg" className="timeframe-arrow-img" />
                </div>
            </button>
            <div className="dropdown-menu dropdown-menu-end border-0 shadow dd-position p-4 custom-filter-width primary-bg-color"
                aria-labelledby="dropdownMenuLink">
                <div className="row">
                    {timeFrameOptions.map(option => (
                        <div className="col-md-4 mb-2 time-frame-dropdown-item" key={option.value} onClick={() => handleOptionClick(option.value)}>
                            <span className="font-medium font-13 primary-text-color ">{option.label}</span>
                        </div>
                    ))}
                </div>
                <div className='row'>
                    <div className='d-flex justify-content-end'>
                        <button
                            className="btn text-nowrap w-md-100 font-medium  font-12 red-legend-color d-flex gap-2 justify-content-center align-items-center py-2 me-3 px-4" onClick={() => handleOptionClick("")}>Clear</button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TimeFrame;