import React from 'react';
import { useTheme } from '../ThemeContext';
import { Theme } from '../../enums/enums';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'dotlottie-player': React.HTMLAttributes<HTMLElement> & {
        src: string;
        background: string;
        speed: string;
        loop: boolean;
        autoplay: boolean;
        style?: React.CSSProperties;
      };
    }
  }
}

function Loader() {
  const { themeType } = useTheme();
  return (
    <div className="container-fluid">
      <div className="overlay">

        <div className="position-absolute top-50 start-50 translate-middle">
          <dotlottie-player 
          src={ themeType == Theme.DARK ? "https://lottie.host/2f1588ba-f3a1-4864-8a43-5fa7b8914fd4/vLoO9M75b0.json" : "https://lottie.host/0ddb3b3b-2b5e-43f7-b32e-f59241ccd4e8/XT836PPMin.json"}
            background="transparent" 
            speed="1" 
            style={{ width: "300px", height: "300px" }} 
            loop 
            autoplay></dotlottie-player>
        </div>
      </div>
    </div>
  );
}

export default Loader;