import React from 'react';
import { LoadReferenceNumbers } from '../../../interface/LoadInterface';
//PS_11 - 12
const ReferenceNumbers: React.FC<{referenceNumber:LoadReferenceNumbers[]}> = ({ referenceNumber }) => {
    return (
        <div className="col-12 col-md-6 mb-3">
            <div className="content-container scroll-list-details h-100">
            <h3 className="font-18 font-semibold primary-text-color mb-3">Reference#</h3>
                {(referenceNumber && referenceNumber?.length > 0) && !(referenceNumber[0]?.key == "" && referenceNumber[0]?.value == "")?
                    <>
                        <ul className="h-100px">
                            {referenceNumber?.map((ref, index) => (
                                <li key={index} className="font-14 font-medium table-text-color mb-3">
                                    {ref.key}:<span className="font-14 font-semibold primary-text-color ms-1">  {ref.value} </span>
                                </li>
                            ))}
                        </ul> </>
                    : <>
                        <div className=" h-100px">
                            <div className="d-flex flex-row justify-content-center align-items-center h-inherit">
                                <span className="no-records-found small me-2"></span>
                                <p className="font-13 font-semibold color-black-v1 m-0">No Data Found</p>
                            </div>
                        </div>
                    </>}
            </div>
        </div>
    );
}

export default ReferenceNumbers;