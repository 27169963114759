// PS_EX_01
import { City, State } from "country-state-city";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { EventsStatus } from "../../enums/enums";
import {
  DropDownOptions,
  Events,
  EventsErrors,
  LoadStopDetails,
} from "../../interface/LoadInterface"; // assuming this is the file with your type definitions
import { addEvent, getDelayReasons, getLoadStops } from "../../service/Api";
import { getTimestampToDate } from "../../utils/Formatting";
import { combineDateTimeToTimestamp } from "../../utils/utils";

//PS_EX_06
const EventPopup: React.FC<{
  events: Events;
  onClose: any;
  onError: any;
  openToast: any;
  updateEvent: boolean;
  getLoads: any;
  openLoader: any;
  closeLoader: any;
}> = ({
  events,
  updateEvent,
  onClose,
  onError,
  openToast,
  getLoads,
  openLoader,
  closeLoader,
}) => {
    //PS_EX_02-PS_EX_03
    const [stopDetails, setStopDetails] = useState<LoadStopDetails[]>([]);
    const [delayReasonDetails, setDelayReasonDetails] = useState<DropDownOptions[]>([]);
    let [errorMessage, setErrorMessage] = useState<EventsErrors>({
      stops: "",
      inDate: "",
      outDate: "",
      inTime: "",
      outTime: "",
      notes: "",
      reasonForDelay: "",
      city: "",
      state: "",
    });
    const [inTime, setInTime] = useState<string>(
      getTimestampToDate(events.inTime, 6) || ""
    );
    const [outTime, setOutTime] = useState<string>(
      getTimestampToDate(events.outTime || "", 6) || ""
    );
    const [outDate, setOutDate] = useState<string | null>(
      events?.outTime
        ? getTimestampToDate(events?.outTime, 2)
        : getTimestampToDate(new Date().toString(), 2)
    );
    const [inDate, setInDate] = useState<string | null>(
      events?.inTime
        ? getTimestampToDate(events?.inTime, 2)
        : getTimestampToDate(new Date().toString(), 2)
    );
    const tmsId: number = Number(localStorage.getItem("tmsId") || 0);

    const [eventDetails, setEventDetails] = useState<Events>(events);
    useEffect(() => {
      // PS_EX_05 fetchLoadDetails would be an async function that calls getLoadDetails and sets state accordingly.
      Promise.all([fetchLoadStops(), fetchLoadDelayReasons()]);
    }, []);

    useEffect(() => {
      const handleEscape = (event: any) => {
        if (event.key === "Escape") {
          onClose();
        }
      };

      document.addEventListener("keydown", handleEscape);

      return () => {
        document.removeEventListener("keydown", handleEscape);
      };
    });

    const fetchLoadDelayReasons = async () => {
      try {
        const response = await getDelayReasons(tmsId);
        if (response?.data?.code === 200) {
          setDelayReasonDetails(response?.data?.data);
        } else {
          setDelayReasonDetails([]);
        }
      } catch (error) {
        setDelayReasonDetails([]);
      }
    };

    const fetchLoadStops = async () => {
      try {
        const response = await getLoadStops(tmsId, eventDetails.loadId);
        if (response?.data?.code === 200) {
          const stopsArray: LoadStopDetails[] = response?.data?.data;
          let shippementDetails = stopsArray.sort((a, b) => {
            // PS_SP_1.7 Assuming `stopSequenceNumber` is a property of the `DistanceData` type to sort by
            return a.stop_sequence_number - b.stop_sequence_number;
          });
          setStopDetails(shippementDetails);
          if (!updateEvent) {
            if (
              eventDetails.event === EventsStatus.ArrivedAtStop ||
              eventDetails.event === EventsStatus.InTransit
            ) {
              const currentStop = stopsArray.find(
                (stop) => stop.stop_id === eventDetails.stopId
              );
              if (currentStop) {
                const nextStopSequenceNumber = currentStop.stop_sequence_number + 1;
                const nextStop = stopsArray.find(
                  (stop) => stop.stop_sequence_number === nextStopSequenceNumber
                );
                if (nextStop) {
                  setEventDetails((prevDetails) => ({
                    ...prevDetails,
                    stopId: nextStop.stop_id,
                    addressLine1: nextStop.address_line_1,
                    addressLine2: nextStop.address_line_2,
                    city: nextStop.city,
                    state: nextStop.state,
                    // Include country if needed and if it's a field on eventDetails
                  }));
                }
              } else {
                const maxStopSequence = stopsArray.reduce((max, stop) => {
                  if (stop.stop_sequence_number < max.stop_sequence_number) {
                    return stop;
                  }
                  return max;
                }, stopsArray[0]);
                setEventDetails((prevDetails) => ({
                  ...prevDetails,
                  stopId: maxStopSequence.stop_id,
                  addressLine1: maxStopSequence.address_line_1,
                  addressLine2: maxStopSequence.address_line_2,
                  city: maxStopSequence.city,
                  state: maxStopSequence.state,
                }));
              }
            }
            if (
              eventDetails.event === EventsStatus.ArrivedAtConsignee ||
              eventDetails.event === EventsStatus.Delivered
            ) {
              const maxStopSequence = stopsArray.reduce((max, stop) => {
                if (stop.stop_sequence_number > max.stop_sequence_number) {
                  return stop;
                }
                return max;
              }, stopsArray[0]);
              setEventDetails((prevDetails) => ({
                ...prevDetails,
                stopId: maxStopSequence.stop_id,
                addressLine1: maxStopSequence.address_line_1,
                addressLine2: maxStopSequence.address_line_2,
                city: maxStopSequence.city,
                state: maxStopSequence.state,
              }));
            }
            if (
              eventDetails.event === EventsStatus.ArriverdAtCustomer ||
              eventDetails.event === EventsStatus.MarkAsLoaded
            ) {
              const stopValue = stopsArray.find(
                (stop) => stop?.stop_id === eventDetails?.stopId
              );
              if (stopValue) {
                setEventDetails((prevDetails) => ({
                  ...prevDetails,
                  stopId: stopValue?.stop_id || 0,
                  addressLine1: stopValue?.address_line_1 || "",
                  addressLine2: stopValue?.address_line_2 || "",
                  city: stopValue?.city || "",
                  state: stopValue?.state || "",
                }));
              } else {
                const maxStopSequence = stopsArray.reduce((max, stop) => {
                  if (stop.stop_sequence_number < max.stop_sequence_number) {
                    return stop;
                  }
                  return max;
                }, stopsArray[0]);
                setEventDetails((prevDetails) => ({
                  ...prevDetails,
                  stopId: maxStopSequence.stop_id,
                  addressLine1: maxStopSequence.address_line_1,
                  addressLine2: maxStopSequence.address_line_2,
                  city: maxStopSequence.city,
                  state: maxStopSequence.state,
                }));
              }
            }
          }
        }
        else {
          setStopDetails([]);
        }
      } catch (error) {
        setStopDetails([]);
      }
    };
    const handleInputChange = () => {
      //PS_EX_40-PS_EX_42
      errorMessage = {
        stops: "",
        inDate: "",
        outDate: "",
        inTime: "",
        outTime: "",
        notes: "",
        reasonForDelay: "",
        city: "",
        state: "",
      };
      if (eventDetails.event !== EventsStatus.Note) {
        if (!inDate) {
          errorMessage.inDate = "Select a value";
        }
        if (!inTime) {
          errorMessage.inTime = "Select a value";
        }
        if (
          eventDetails.event === EventsStatus.MarkAsLoaded ||
          eventDetails.event === EventsStatus.InTransit ||
          eventDetails.event === EventsStatus.Delivered
        ) {
          if (!outDate) {
            errorMessage.outDate = "Select a value";
          }
          if (!outTime) {
            errorMessage.outTime = "Select a value";
          }
        }
        if (
          eventDetails.event === EventsStatus.MarkAsLoaded ||
          eventDetails.event === EventsStatus.InTransit ||
          eventDetails.event === EventsStatus.ArrivedAtStop
        ) {
          if (!eventDetails.stopId) {
            errorMessage.stops = "Select a value";
          }
        }
        if (eventDetails.event === EventsStatus.InTransit) {
          if (!eventDetails.city) {
            errorMessage.city = "Select a value";
          }
          if (!eventDetails.state) {
            errorMessage.state = "Select a value";
          }
        }
      }
      if (!eventDetails.onTime && !eventDetails.reasonCode) {
        errorMessage.reasonForDelay = "Select a value";
      }
      if (eventDetails.event === EventsStatus.Note) {
        if (!eventDetails.notes) {
          errorMessage.notes = "Enter a value";
        }
      }
      setErrorMessage(errorMessage);
    };

    const convertToUTC = (inDate: string, inTime: string) => {
      if (inDate && inTime) {
        // Split inTime to get the time part without timezone offset
        const timeWithoutOffset = inTime.split(" ")[0]; // "01:15:00"

        // Construct the datetime string in ISO 8601 format
        const dateTimeString = `${inDate}T${timeWithoutOffset}`;

        // Parse datetime string
        const dateTime = DateTime.fromISO(dateTimeString, {
          zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        });

        if (dateTime.isValid) {
          const utcDateTime = dateTime.toUTC();
          return utcDateTime.toString();
        } else {
          return "Invalid Date";
        }
      } else {
        return "Missing Date or Time";
      }
    };

    const handleSubmit = async () => {
      //PS_EX_39
      handleInputChange();
      const hasError = Object.values(errorMessage).every((error) => error === "");
      if (!hasError) {
        // If there are error messages, do not proceed with the API call
        return;
      }
      try {
        openLoader();
        //PS_EX_43
        let inTimes = null;
        if (inDate && inTime) {
          const combinedUTC = combineDateTimeToTimestamp(
            `${inDate} ${inTime.split(" ")[0]}`
          );
          inTimes = new Date(combinedUTC);
        }
        let outTimes = null;
        if (
          (outDate && outTime && eventDetails.event == EventsStatus.InTransit) ||
          eventDetails.event == EventsStatus.MarkAsLoaded ||
          eventDetails.event == EventsStatus.Delivered
        ) {
          const combinedUTC = combineDateTimeToTimestamp(
            `${outDate} ${outTime.split(" ")[0]}`
          );
          outTimes = new Date(combinedUTC);
        }

        const event: any = {
          tracking_event_id: eventDetails.trackingeventId || null,
          event_type: eventDetails.eventType,
          event: eventDetails.event,
          event_code: eventDetails.eventCode,
          in_time: inTimes,
          stop_id: eventDetails?.stopId ? eventDetails?.stopId : 0,
          notes: eventDetails.notes,
          delay_reason: !eventDetails.onTime && eventDetails.reasonForDelay ? eventDetails.reasonForDelay : "",
          reason_code: !eventDetails.onTime && eventDetails.reasonCode ? eventDetails.reasonCode : "",
          is_manual: true,
          city: eventDetails.city,
          state: eventDetails.state,
          event_timestamp_local: new Date().toISOString()
        };
        if (
          (outDate && outTime && eventDetails.event == EventsStatus.InTransit) ||
          eventDetails.event == EventsStatus.MarkAsLoaded ||
          eventDetails.event == EventsStatus.Delivered
        ) {
          event.out_time = outTimes;
        }
        onClose();

        //PS_EX_54
        const response = await addEvent(event, eventDetails.tmsId, eventDetails.loadId);
        if (response.data.code === 200) {
          closeLoader();
          getLoads();
          openToast("Event Updated Successfully");
        } else {
          closeLoader();
          //PS_EX_56-PS_EX_58
          onClose();
          onError();
        }
        closeLoader();
      } catch (error) {
        closeLoader();
        onClose();
        onError();
      }
    };
    const handleCancel = () => {
      //PS_EX_61-PS_EX_67
      onClose();
    };
    const parseTimeString = (timeString?: string): Date | undefined => {
      if (!timeString) return undefined;
      const [hours, minutes] = timeString.split(":");
      const date = new Date();
      date.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0, 0);
      return date;
    };
    return (
      //PS_EX_27-PS_EX_38
      <>
        <div
          className="modal fade show"
          id="exampleModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-modal="true"
          role="dialog"
          style={{
            backgroundColor: "rgba(0, 0, 0, .5)",
            display: "block",
          }}
        >
          <div className="modal-dialog modal-dialog-centered ">
            <div className="modal-content secondary-bg-color p-2">
              <div className="modal-header border-0">
                <h1
                  className="modal-title fs-5 secondary-header"
                  id="exampleModalLabel"
                >
                  {eventDetails.event === EventsStatus.ArriverdAtCustomer &&
                    "Arrived at Customer"}
                  {eventDetails.event === EventsStatus.ArrivedAtConsignee &&
                    "Arrived at Consignee"}
                  {eventDetails.event === EventsStatus.ArrivedAtStop &&
                    "Arrived at Stop"}
                  {eventDetails.event === EventsStatus.MarkAsLoaded &&
                    "Mark as Loaded"}
                  {eventDetails.event === EventsStatus.InTransit && "In Transit"}
                  {eventDetails.event === EventsStatus.Delivered && "Delivered"}
                  {eventDetails.event === EventsStatus.Note && "Add Note"}
                </h1>
                <button
                  type="button"
                  className="btn-close close-icon"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleCancel}
                />
              </div>
              <div className="modal-body event-popup pt-2">
                <div className="row">
                  {eventDetails.event !== EventsStatus.Note && (
                    <div className="col-md-12">
                      <label
                        className="font-semibold custom-label pb-2"
                        htmlFor="Stop"
                      >
                        {eventDetails.event === EventsStatus.ArriverdAtCustomer ||
                          eventDetails.event === EventsStatus.ArrivedAtConsignee ||
                          eventDetails.event === EventsStatus.Delivered
                          ? "Stop"
                          : eventDetails.event === EventsStatus.ArrivedAtStop ||
                            eventDetails.event === EventsStatus.MarkAsLoaded
                            ? "Choose Stop"
                            : eventDetails.event === EventsStatus.InTransit
                              ? "Next Stop"
                              : ""}
                      </label>
                      {eventDetails.event === EventsStatus.ArriverdAtCustomer ||
                        eventDetails.event === EventsStatus.ArrivedAtConsignee ||
                        eventDetails.event === EventsStatus.Delivered ? (
                        <input
                          type="text"
                          className="form-control custom-form-input area-bg  border-0"
                          id="Stop"
                          value={
                            stopDetails.find(
                              (stop) => stop.stop_id === eventDetails.stopId
                            )
                              ? `${stopDetails.find(
                                (stop) => stop.stop_id === eventDetails.stopId
                              )?.address_line_1
                                } ${stopDetails.find(
                                  (stop) => stop.stop_id === eventDetails.stopId
                                )?.city
                                } ${stopDetails.find(
                                  (stop) => stop.stop_id === eventDetails.stopId
                                )?.state
                                }`.length > 32
                                ? `${stopDetails.find(
                                  (stop) =>
                                    stop.stop_id === eventDetails.stopId
                                )?.address_line_1
                                  } ${stopDetails.find(
                                    (stop) =>
                                      stop.stop_id === eventDetails.stopId
                                  )?.city
                                  } ${stopDetails.find(
                                    (stop) =>
                                      stop.stop_id === eventDetails.stopId
                                  )?.state
                                  }`.substring(0, 32) + "..."
                                : `${stopDetails.find(
                                  (stop) =>
                                    stop.stop_id === eventDetails.stopId
                                )?.address_line_1
                                } ${stopDetails.find(
                                  (stop) =>
                                    stop.stop_id === eventDetails.stopId
                                )?.city
                                } ${stopDetails.find(
                                  (stop) =>
                                    stop.stop_id === eventDetails.stopId
                                )?.state
                                }`
                              : "N/A"
                          }
                          disabled
                        />
                      ) : (
                        <select
                          className="form-select custom-form-input primary-bg-color custom-select-arrow primary-border-color"
                          aria-label="Default select example"
                          value={eventDetails.stopId}
                          onChange={(e) => {
                            const selectedStop = stopDetails.find(
                              (stop) => stop.stop_id === parseInt(e.target.value)
                            );
                            if (selectedStop) {
                              setEventDetails({
                                ...eventDetails,
                                stopId: selectedStop.stop_id,
                                addressLine2: selectedStop.address_line_2,
                                addressLine1: selectedStop.address_line_1,
                                city: selectedStop.city,
                                state: selectedStop.state,
                              });
                            }
                          }}
                        >
                          {!eventDetails.stopId && (
                            <option value="Select">Select</option>
                          )}
                          {stopDetails.map((stop) => (
                            <option key={stop.stop_id} value={stop.stop_id}>
                              {`${stop.address_line_1} ${stop.city} ${stop.state}`
                                .length > 32
                                ? `${stop.address_line_1} ${stop.city} ${stop.state}`.substring(
                                  0,
                                  32
                                ) + "..."
                                : `${stop.address_line_1} ${stop.city} ${stop.state}`}
                            </option>
                          ))}
                        </select>
                      )}
                      <div className="pt-1">
                        <label className="required-color font-12 font-regular">
                          {errorMessage.stops}
                        </label>
                      </div>
                    </div>
                  )}
                  {eventDetails.event !== EventsStatus.Note && (
                    <div className="col-md-6">
                      <label
                        className="font-semibold custom-label pb-2"
                        htmlFor="InDate"
                      >
                        {eventDetails.event === EventsStatus.InTransit
                          ? "ETA Date"
                          : "In Date"}
                        <span className="required-color"> *</span>
                      </label>
                      <div className="position-relative">
                        <input
                          type="date"
                          className="form-control custom-form-input primary-bg-color custom-form  primary-border-color font-regular py-2 cursor-pointer"
                          id="ExpiresOn"
                          placeholder="Enter New Password"
                          value={getTimestampToDate(inDate || "", 2) || ""}
                          onChange={(e) => setInDate(e.target.value)}
                        />
                        <img
                          className="position-absolute custom-input-icon cursor-pointer"
                          src="/images/calander-icon.svg"
                          alt="calander-icon"
                        ></img>
                        {/* <img
                                                className="position-absolute custom-input-icon"
                                                src="/images/calander-icon.svg"
                                                alt="calander-icon"
                                            /> */}
                      </div>
                      <div className="pt-1">
                        <label className="required-color font-12 font-regular">
                          {errorMessage.inDate}
                        </label>
                      </div>
                    </div>
                  )}
                  {eventDetails.event !== EventsStatus.Note && (
                    <div className="col-md-6">
                      <label
                        className="font-semibold custom-label pb-2"
                        htmlFor="InTime"
                      >
                        {eventDetails.event === EventsStatus.InTransit
                          ? "ETA Time"
                          : "In Time"}
                        <span className="required-color"> *</span>
                      </label>
                      <div className="position-relative">
                        <ReactDatePicker
                          selected={parseTimeString(inTime)}
                          onChange={(date: Date | null) =>
                            setInTime(date ? date.toTimeString() : "")
                          } // Updated this line
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="HH:mm"
                          id="Time"
                          timeFormat="HH:mm"
                          placeholderText="--:--"
                          className="form-control custom-form-input primary-bg-color primary-border-color py-2"
                        />

                        <label htmlFor="Time">
                          <img
                            className="position-absolute custom-input-icon"
                            src="/images/clock-icon.svg"
                            alt="clock-icon"
                          />
                        </label>
                      </div>
                      <div className="pt-1">
                        <label className="required-color font-12 font-regular">
                          {errorMessage.inTime}
                        </label>
                      </div>
                    </div>
                  )}
                  <>
                    {eventDetails.event === EventsStatus.InTransit && (
                      <>
                        <div className="col-md-6">
                          <label
                            className="font-semibold custom-label pb-2"
                            htmlFor="City"
                          >
                            City <span className="required-color"> *</span>
                          </label>
                          <select
                            className="form-select custom-form-input primary-bg-color custom-select-arrow primary-border-color"
                            aria-label="City"
                            value={eventDetails.city || ""}
                            onChange={(e) => {
                              const selectedCity = e.target.value;
                              setEventDetails({
                                ...eventDetails,
                                city: selectedCity,
                              });
                            }}
                          >
                            {!eventDetails.city && (
                              <option value="select">Select</option>
                            )}
                            {City.getCitiesOfState(
                              "US",
                              (eventDetails.state as string) || ""
                            ).map((city) => (
                              <option key={city.name} value={city.name}>
                                {city.name}
                              </option>
                            ))}
                          </select>
                          <div className="pt-1">
                            <label className="required-color font-12 font-regular">
                              {errorMessage.city}
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <label
                            className="font-semibold custom-label pb-2"
                            htmlFor="State"
                          >
                            State <span className="required-color"> *</span>
                          </label>
                          <select
                            className="form-select custom-form-input primary-bg-color custom-select-arrow primary-border-color"
                            aria-label="State"
                            value={(eventDetails.state as string) || ""}
                            onChange={(e) => {
                              const selectedState = e.target.value;
                              setEventDetails({
                                ...eventDetails,
                                state: selectedState,
                              });
                            }}
                          >
                            {!eventDetails.state && (
                              <option value="">Select</option>
                            )}
                            {State.getStatesOfCountry("US").map(
                              (state) =>
                                City.getCitiesOfState("US", state.isoCode)
                                  .length > 0 && (
                                  <option key={state.name} value={state.isoCode}>
                                    {state.name}
                                  </option>
                                )
                            )}
                          </select>
                          <div className="pt-1">
                            <label className="required-color font-12 font-regular">
                              {errorMessage.state}
                            </label>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                  <>
                    {(eventDetails.event === EventsStatus.InTransit ||
                      eventDetails.event === EventsStatus.MarkAsLoaded ||
                      eventDetails.event === EventsStatus.Delivered) && (
                        <>
                          <div className="col-md-6">
                            <label
                              className="font-semibold custom-label pb-2"
                              htmlFor="InDate"
                            >
                              {eventDetails.event === EventsStatus.InTransit
                                ? "Truck Location Date"
                                : " Out Date"}
                              <span className="required-color"> *</span>
                            </label>
                            <div className="position-relative">
                              <input
                                type="date"
                                className="form-control custom-form-input primary-bg-color custom-form  primary-border-color font-regular py-2"
                                id="ExpiresOn"
                                value={getTimestampToDate(outDate || "", 2) || ""}
                                onChange={(e) => setOutDate(e.target.value)}
                                placeholder="Enter New Password"
                              />
                              <img
                                className="position-absolute custom-input-icon cursor-pointer"
                                src="/images/calander-icon.svg"
                                alt="calander-icon"
                              ></img>
                            </div>
                            <div className="pt-1">
                              <label className="required-color font-12 font-regular">
                                {errorMessage.outDate}
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <label
                              className="font-semibold custom-label pb-2"
                              htmlFor="InTime"
                            >
                              {eventDetails.event === EventsStatus.InTransit
                                ? "Truck location Time"
                                : "Out Time"}
                              <span className="required-color"> *</span>
                            </label>
                            <div className="position-relative">
                              <ReactDatePicker
                                selected={parseTimeString(outTime)}
                                onChange={(date: Date | null) =>
                                  setOutTime(date ? date.toTimeString() : "")
                                }
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Time"
                                dateFormat="HH:mm"
                                id="outTime"
                                timeFormat="HH:mm"
                                placeholderText="--:--"
                                className="form-control custom-form-input primary-bg-color primary-border-color py-2"
                              />
                              <label htmlFor="outTime">
                                <img
                                  className="position-absolute custom-input-icon"
                                  src="/images/clock-icon.svg"
                                  alt="clock-icon"
                                />
                              </label>
                            </div>
                            <div className="pt-1">
                              <label className="required-color font-12 font-regular">
                                {errorMessage.outTime}
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                  </>
                  <div className="col-md-6 mb-4 pe-5">
                    <label
                      className="font-semibold custom-label pb-2"
                      htmlFor="OnTime"
                    >
                      On Time
                    </label>
                    <div className="d-flex align-items-center gap-4 py-2">
                      <div className="form-check d-flex align-items-center gap-2 p-0">
                        <input
                          className="form-check-input primary-bg-color m-0 form-checked primary-border-color"
                          type="radio"
                          name="Radiotime"
                          id="RadioYes"
                          checked={eventDetails.onTime}
                          onChange={(e) =>
                            setEventDetails({ ...eventDetails, onTime: true })
                          }
                        />
                        <label
                          className="form-check-label font-regular custom-label"
                          htmlFor="RadioYes"
                        >
                          Yes
                        </label>
                      </div>
                      <div className="form-check d-flex align-items-center gap-2 p-0">
                        <input
                          className="form-check-input primary-bg-color m-0 form-checked primary-border-color"
                          type="radio"
                          name="Radiotime"
                          id="RadioNo"
                          checked={!eventDetails.onTime}
                          onChange={(e) =>
                            setEventDetails({ ...eventDetails, onTime: false })
                          }
                        // defaultChecked=""
                        />
                        <label
                          className="form-check-label font-regular custom-label active"
                          htmlFor="RadioNo"
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                  {!eventDetails.onTime && (
                    <div className="col-md-12">
                      <label
                        className="font-semibold custom-label pb-2"
                        htmlFor="ReasonforDelay"
                      >
                        Reason for Delay{" "}
                        <span className="required-color"> *</span>
                      </label>
                      <select
                        className="form-select custom-form-input primary-bg-color custom-select-arrow primary-border-color"
                        aria-label="Default select example"
                        value={eventDetails.reasonCode.toString()}
                        onChange={(e) => {
                          const selectedCode = e.target.value;
                          const selectedReason = delayReasonDetails.find(reason => reason.code === selectedCode);
                          setEventDetails({
                            ...eventDetails,
                            reasonCode: selectedCode,
                            reasonForDelay: selectedReason ? selectedReason.display_text : ''
                          });
                        }}
                      >
                        {(!eventDetails.reasonCode ||
                          delayReasonDetails.length === 0) && (
                            <option value="">Select</option>
                          )}
                        {delayReasonDetails.map((reason) => (
                          <option
                            key={reason.code}
                            value={reason.code}
                          >
                            {reason.display_text}
                          </option>
                        ))}
                      </select>
                      <div className="pt-1">
                        <label className="required-color font-12 font-regular">
                          {errorMessage.reasonForDelay}
                        </label>
                      </div>
                    </div>
                  )}
                  <div className="col-md-12">
                    <label
                      className="font-semibold custom-label pb-2"
                      htmlFor="Notes"
                    >
                      Notes{" "}
                      {eventDetails.event == EventsStatus.Note && (
                        <span className="required-color"> *</span>
                      )}
                    </label>
                    <textarea
                      className="form-control custom-form-input text-box primary-border-color"
                      placeholder="Enter Notes"
                      id="Notes"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") handleSubmit();
                      }}
                      value={eventDetails.notes}
                      onChange={(e) =>
                        setEventDetails({
                          ...eventDetails,
                          notes: e.target.value.trimStart(),
                        })
                      }
                    />
                    <div className="pt-1">
                      <label className="required-color font-12 font-regular">
                        {errorMessage.notes}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer border-0 pt-0">
                <button
                  type="button"
                  className="btn text-btn  font-14 font-medium px-4"
                  data-bs-dismiss="modal"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn primary-btn font-14   font-medium px-4"
                  data-bs-dismiss="modal"
                  onClick={handleSubmit}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

export default EventPopup;
