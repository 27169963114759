import React, {useEffect, useMemo, useState } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { StopLocations } from '../../../interface/LoadInterface';
import { useTheme } from '../../ThemeContext';
import { Theme } from '../../../enums/enums';
import { nightModeStyle } from '../../../Constant';

interface MapContainerProps {
    data: StopLocations[];
}

/**
 *  PS-13 to PS-43
 * This is used post the user details to signup API
 * @param MapContainerProps which is array of stop locations. 
 * @returns the Locations marked with stop locations.
 * Initially the map instance is assigned to the Map state variable on load of the MAP
 * @function setMapMarkers Loops through the Locations(city,state) and fetch the latitude longtitude value for the locations.
 * and set in the state variable marker, which will trigger useEffect
 * @function geocodePlace This function returns the latitude and longitude of the recieved address, city and state.
 * If Map instance is available markers are looped, to show appropriate bounds for the obtained
 * latitude and longtitude values and fit them inside the map using instance obtained
 */
const MapContainer: React.FC<MapContainerProps> = ({ data }) => {
    const [map, setMap] = useState<google.maps.Map | null>(null);
    const [markers, setMarkers] = useState<google.maps.Marker[]>([]);
    
    //PS_18 Declare a variable themeType to store the current theme.
    const { themeType } = useTheme();

    useEffect(() => {
        if (map) {
            markers?.forEach(marker => marker?.setMap(null));
            markers?.forEach(marker => marker?.setMap(map));

            const bounds = new google.maps.LatLngBounds();
            markers.forEach(marker => {
                bounds.extend(marker.getPosition() as google.maps.LatLng);
            });
            map.fitBounds(bounds);
        }
    }, [map, markers]);

    const handleLoad = (mapInstance: google.maps.Map) => {
        setMap(mapInstance);
    };

    useEffect(() => {
        setMapMarkers();
    }, [data]);

    const setMapMarkers = async () => {
        const newMarkers: google.maps.Marker[] = [];
        if (data) {
            for (const item of data) {
                if (item?.city && item?.state) {
                    try {
                        const location = await geocodePlace(item?.city, item?.state, item?.address_line1);
                        newMarkers.push(
                            new google.maps.Marker({
                                position: location,
                                title: item?.address_line1,
                            })
                        );
                    } catch (error) {
                        console.error(error);
                    }
                }
            }
        }
        setMarkers(newMarkers);
    };

    const geocodePlace = async (address: string, city: string, state: string): Promise<google.maps.LatLng> => {
        return new Promise<google.maps.LatLng>((resolve, reject) => {
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode({ address: `${address},${city},${state}` }, (results, status) => {
                if (status === 'OK' && results) {
                    resolve(results[0]?.geometry?.location);
                } else {
                    reject(`Could not find coordinates for ${address}, ${city}, ${state}`);
                }
            });
        });
    };

    //PS_42 Declare a variable mapOption wiht useMemo and check whether the current theme is dark,
    //If true, then assign the value of nightModeStyle from constants file else, undefined
    //with dependency as themeType.
    const mapOptions = useMemo((): google.maps.MapOptions => ({
        styles: themeType === Theme.DARK ? nightModeStyle.styles : undefined
    }), [themeType]);
    
    return (
        <GoogleMap
            onLoad={handleLoad}
            mapContainerStyle={{ height: '720px', width: '100%' }}
            options={mapOptions}
        >

            {markers?.map((marker, index) => (
                <Marker
                    key={index}
                    position={marker.getPosition() as google.maps.LatLng}
                    title={marker.getTitle() || ""}
                />
            ))}
        </GoogleMap>
    );
};


export default MapContainer;