//PS_3PL_1.0
import { format } from 'date-fns';
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { StatusInfo, TMSUserDetails, ThreePlRequest, tmsgriddropdownstatus } from "../../interface/Interface";
import { ReportFilter } from '../../interface/ReportsInterface';
import { getTMSUsers, getTmsStatus, retrieveUserDetails } from "../../service/Api"; // Assuming these functions are exported from api.ts
import { encryptUserId } from '../../utils/utils';
import Loader from "../Resuables/Loader";
import NoDataFound from "../Resuables/NoDataFound";
import NoResultsFound from "../Resuables/NoResultsFound";
import PaginationComponent from "../Resuables/PaginationComponent";
import { SomethingWentWrong } from "../Resuables/SomethingWentWrong";
import { SuccessToast } from "../Resuables/SuccessToast";
import { DropDownOptions } from '../../interface/LoadInterface';

//PS_3PL_1.0

let filterObj: ReportFilter = {
    search_data: "",
    from_date: "",
    to_date: "",
    sort_column: "",
    sort_type: "",
    start_index: 0,
    page_size: 10
}

//PS_3PL_1.1
const TMSDetails = () => {
    const [sortHide, setSortHide] = useState<string>('');
    const location = useLocation();
    const [loader, setLoader] = useState(false);
    const [openToastMessage, setOpenToastMessage] = useState<boolean>(location.state?.openToastMessage || false);
    const actions = location?.state?.action
    //PS_3PL_1.2
    const [organizationDetails, setOrganizationDetails] = useState<TMSUserDetails[]>([]);
    //PS_3PL_1.4
    let [statusDetails, setStatusDetails] = useState<tmsgriddropdownstatus[]>([]);
    //PS_3PL_1.5
    const [noResults, setNoResults] = useState(false);
    //PS_3PL_1.3
    let [tmsFilter, setTMSFilter] = useState<ReportFilter>(filterObj);
    let [tmsStatus, setTMSStatus] = useState<string>('');
    let [connectionStatus, setConnectionStatus] = useState<string>('');
    //PS_3PL_1.5
    const [totalPages, setTotalPages] = useState(0);
    //PS_3PL_1.4
    const [openSomethingWentWrongPopup, setOpenSomethingWentWrongPopup] = useState(false);

    //PS_3PL_1.4
    const [notFound, setNotFound] = useState(false);
    const [totalCount, setTotalCount] = useState(0)
    const [isFilterOpen, setIsFilterOpen] = useState(false);

    //Declare a variable searchInput and initialize it to empty string
    const [searchInput, setSearchInput] = useState("");

    const navigate = useNavigate();
    //PS_3PL_1.7
    //use Effect to retireve data 
    useEffect(() => {
        Promise.all([retrieveTmsStatus(), retrieveTMSUsers()]);
    }, []);
    //PS_3PL_1.50
    //Change total Count
    useEffect(() => {
        //PS_3PL_1.51
        let totalPagesCount = Math.ceil(totalCount / 10); setTotalPages(totalPagesCount);
    }, [totalCount]);
    // display taoster message

    useEffect(() => {
        if (openToastMessage) {
            const timer = setTimeout(() => {
                setOpenToastMessage(false); // Hide the toast message after 10 seconds
            }, 3000);

            return () => clearTimeout(timer); // Clear the timer on component unmount or when openToastMessage changes
        }
    }, [openToastMessage]);
    //PS_3PL_1.7
    /**
     * This function retrieves the status data and checks if data is present 
     */
    //PS_02 - handle inpersonate
    const handleImpersonate = async (tmsId: number, userId: string) => {
        setLoader(true)
        try {
            //PS_03
            const response = await retrieveUserDetails(tmsId)
            if (response?.status == 200) {
                localStorage.setItem("tmsId", tmsId.toString());
                localStorage.setItem("isImpersonate", 'true')
                localStorage.setItem("tmsname", response?.data?.data?.tms_name)
                localStorage.setItem("username", response?.data?.data?.user_name)
                localStorage.setItem("emailId", response?.data?.data?.email_id)
                navigate("/loadmetrics")
                window.location.reload()
            }
            else {
                setOpenSomethingWentWrongPopup(true)
            }
            setLoader(false)


        } catch (error) {
            setLoader(false)
            setOpenSomethingWentWrongPopup(true)
        }

    }

    const retrieveTmsStatus = async () => {

        try {
            setLoader(true)
            const response = await getTmsStatus();
            //PS_3PL_1.8
            if (response.data.code == 200) {

                //PS_3PL_1.9
                setStatusDetails(response.data.data);
            } else {
                //PS_3PL_1.9
                setStatusDetails([]);
            }
        } catch (e: any) {
            console.error(e);

        } finally {
            setLoader(false)
        }

    };
    //PS_3PL_1.49
    /**
     * This Function handles removal of filters and retrieves grid data
     */
    const handleClear = () => {
        tmsFilter.from_date = ""
        tmsFilter.to_date = ""
        setTMSFilter(prevFilter => ({
            ...prevFilter,
            from_date: tmsFilter.from_date,
            to_date: tmsFilter.to_date
        }));
        tmsStatus = ''
        setTMSStatus(tmsStatus)
        connectionStatus = ''
        setConnectionStatus(connectionStatus)
        retrieveTMSUsers();

    }
    //PS_3PL_1.14
    /**
     * This Function gets the Organization details from Backend endpoint
     * @param request 
     */
    const retrieveTMSUsers = async () => {
        let isFilter: boolean = (tmsFilter.search_data || tmsFilter.from_date || tmsFilter.to_date || tmsStatus || connectionStatus || tmsFilter.from_date || tmsFilter.to_date ? true : false);
        try {
            setLoader(true)
            //PS_3PL_1.15
            const response = await getTMSUsers(tmsFilter, tmsStatus, connectionStatus);
            //PS_3PL_1.17
            if (response?.data?.code === 200) {
                setOrganizationDetails(response?.data?.data);
                if (!response?.data?.data) {
                    if (isFilter) {
                        setTotalCount(0)
                        setNoResults(true)
                        setNotFound(false)
                    } else {
                        setTotalCount(0)
                        setNotFound(true)
                        setNoResults(false)
                    }
                } else {
                    setTotalCount(response?.data?.record_count ? response?.data?.record_count : 0)
                    setNotFound(false)
                    setNoResults(false)


                }
                //PS_3PL_1.18
            } else if (response.status !== 200) {
                setOrganizationDetails([]);
                setNotFound(true);
                setOpenSomethingWentWrongPopup(true);
            }

        } catch (e: any) {
            setOrganizationDetails([]);
            setOpenSomethingWentWrongPopup(true);
            if (isFilter) {
                setNoResults(true)
                setNotFound(false)
            } else {
                setNotFound(true)
                setNoResults(false)

            }
            console.error(e);
        } finally {
            setLoader(false)
        }
    };
    //PS_3PL_1.13
    const bindCompanyStatusDropDown = () => {
        return statusDetails[0]?.tms_statuses.map((obj: DropDownOptions) => (
            <option key={obj.code} value={obj.code}>
                {obj.display_text}
            </option>
        ));

    }
    //PS_3PL_1.13
    /**
     * This function gets the all the status drop values from a state and 
     * then binds the Connection status values using 
     * 
     * @returns Jsx.ELement with DropDown data
     */
    const bindConnectionStatusDropDown = () => {
        return statusDetails[1]?.tms_conn_statuses?.map((obj: DropDownOptions) => {
            return (<option value={obj?.code}>
                {obj?.display_text}
            </option>)
        });

    }
    const toPascalCase = (str: string) => {
        return str
            .split("_") // Split the string into words using underscores
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
            .join(' '); // Join the words back together with a space
    };
    //PS_3PL_1.22
    /**
     * This function checks if the values are present in the details state variable and 
     * If there are no values present the show no records found if filters are present then show no results founds
     * If values are present then all the datas are set onto <td> tags and return
     * @returns Jsx.ELement with Grid table data
     */
    const bind3plGrid = () => {
        //PS_3PL_1.22
        return organizationDetails?.map((obj: TMSUserDetails) => {
            //PS_3PL_1.34
            return (
                <tr>
                    <td className="text-nowrap">{obj?.name}</td>
                    <td className="text-nowrap">{obj?.user_name}</td>
                    <td className="text-nowrap">{obj?.business_email}</td>
                    <td className="text-nowrap">{obj?.phone_number === "" || !obj?.phone_number ? '-' : obj?.phone_number.replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3')}</td>
                    <td className="text-nowrap">{toPascalCase(obj?.status || '')}</td>
                    {obj?.connection_status.toLowerCase() === "online" ? <> <td className="text-nowrap">
                        <span className="d-flex align-items-center">
                            <span className="green-legend me-2" />
                            <span>Online</span>
                        </span>
                    </td></> : <> <td className="text-nowrap">
                        <span className="d-flex align-items-center">
                            <span className="red-legend me-2"></span>
                            <span>Offline</span></span>
                    </td></>}

                    <td className="text-nowrap">{obj?.valid_till === "" || !obj?.valid_till ? '-' : format(new Date(obj?.valid_till), 'MM/dd/yyyy')}</td>
                    <td className="text-center d-flex justify-content-center">
                        <button
                            type="button"
                            className="border-0 m-0 border-end px-3 bg-transparent"
                            onClick={() => { navigate("/tms-creation", { state: { companyId: obj?.tms_id, companyName: obj?.name } }) }}
                        >
                            <img src="images/edit-icon.svg" alt="Edit" title="Edit" />
                        </button>
                        <button
                            disabled={toPascalCase(obj.status) === "Inactive" ? true : false}
                            type="button"
                            className="border-0 m-0 bg-transparent px-3"
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleImpersonate(obj?.tms_id || 0, obj?.user_id || "")}
                        >
                            <img
                                src="images/impersonation.svg"
                                alt="Impersonate"
                                title="Impersonate"
                            />
                        </button>
                    </td>
                </tr>)
        });

    }
    //PS_3PL_1.27
    /**
     * This function is used to set the request state 
     * then grid data is again receieved using updated request
     * @param event 
     */
    const handleSort = async (event: any) => {
        try {
            //PS_3PL_1.28
            const sortId = event.target.id;
            const sortValues = sortId.split("@");
            //PS_3PL_1.29
            tmsFilter.sort_type = sortValues[1]
            tmsFilter.sort_column = sortValues[0]
            setTMSFilter(prevFilter => ({
                ...prevFilter,
                sort_column: tmsFilter.sort_column,
                sort_type: tmsFilter.sort_type
            }));
            retrieveTMSUsers();
            setSortHide(sortId)
        } catch (e: any) {
            console.log(e)
        } finally {
            setLoader(false)
        }

    };
    //PS_3PL_1.37--PS_3PL_1.41
    const handleSearch = async () => {
        tmsFilter.search_data = searchInput.trim();
        tmsFilter.start_index = 0
        setTMSFilter({
            ...tmsFilter,
            start_index: tmsFilter.start_index,
            search_data: tmsFilter.search_data
        })
        await retrieveTMSUsers();
    };
    //PS_3PL_1.57
    /**
     * This function is used to use to update the page change click
     * the selected page is sent as start index * 10 
     * @param data 
     */
    const handlePageClick = (data: { selected: number; }) => {
        //PS_3PL_1.57
        tmsFilter.start_index = data.selected * 10
        setTMSFilter(prevFilter => ({
            ...prevFilter,
            start_index: tmsFilter.start_index
        }));
        //PS_3PL_1.58
        retrieveTMSUsers()
    }
    return (
        <>
            <div>
                {loader ? <Loader /> : <></>}
                {openSomethingWentWrongPopup ? <SomethingWentWrong onClose={() => setOpenSomethingWentWrongPopup(false)} /> : null}

                {openToastMessage && (actions == "create" ? SuccessToast("Created 3PL Successfully") : SuccessToast(" 3PL details updated successfully"))}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 fixedhd">
                            <div className="px-4 py-4 mb-3 border-0 rounded-3">
                                <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap gap-3 ">
                                    <h5 className="mb-0 color-black-v1 font-22 font-bold">3PL</h5>
                                    <div className="d-flex gap-3 w-md-100">
                                        <div className="input-group input-search">
                                            <input
                                                type="search"
                                                className="form-control border-0 font-14 font-regular primary-bg-color"
                                                placeholder="Search"
                                                onKeyDown={e => { if (e.key === "Enter") handleSearch() }}
                                                onChange={(e) => {
                                                    const inputValue = e.target.value;
                                                    if (searchInput === '' && inputValue.trim() === '') {
                                                        // If the input is empty and the new value is only spaces, don't update
                                                        return;
                                                    }
                                                    setSearchInput(e.target.value)
                                                }}
                                                value={searchInput}
                                            />
                                            <button
                                                className="btn border-0 search-input-btn primary-bg-color"
                                                type="button"
                                                onClick={handleSearch}
                                            >
                                                <img src="images/search-icon.svg" alt="search" />
                                            </button>
                                        </div>
                                        {/* <button class="btn filter-btn border-0 custom-border-box-shadow">
                          <img src="images/filter-icon.svg" alt="filter-icon">
                      </button> */}
                                        <div className="dropdown">
                                            <button
                                                className={isFilterOpen ? "btn filter-btn border-0 custom-border-box-shadow primary-bg-color show" : "btn filter-btn border-0 custom-border-box-shadow primary-bg-color"}
                                                aria-expanded="true"
                                                onClick={() => {
                                                    setIsFilterOpen(!isFilterOpen);
                                                }}
                                            >
                                                <img src="images/filter-icon.svg" alt="filter-icon" />
                                            </button>
                                            {/*filter*/}
                                            <div
                                                className={isFilterOpen ? "dropdown-menu dropdown-menu-end border-0 shadow p-3 custom-filter-width secondary-bg-color show top-40" : "dropdown-menu dropdown-menu-end border-0 shadow dd-position p-3 custom-filter-width secondary-bg-color"}
                                                aria-labelledby="dropdownMenuLink"
                                            >
                                                <div className="d-flex justify-content-between align-items-center mb-4">
                                                    <h5 className="font-bold font-20 mb-0 custom-header-color form-text-color">
                                                        Advanced Filter
                                                    </h5>
                                                    <span>
                                                        <img
                                                            className="cursor-pointer left-arrow"
                                                            src="images/cross-icon-black.svg"
                                                            alt="close"
                                                            onClick={() => {
                                                                setIsFilterOpen(!isFilterOpen);
                                                            }}
                                                        />
                                                    </span>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6 mb-4">
                                                        <label
                                                            className="form-label font-14 font-semibold label-color form-text-color"
                                                            htmlFor="Status"
                                                        >
                                                            Status
                                                        </label>
                                                        <select
                                                            className="form-select custom-form-input secondary-bg-color form-field-border"
                                                            aria-label="Default select example"
                                                            id="Status"
                                                            style={{ cursor: 'pointer' }}
                                                            onChange={e => setTMSStatus(e.target.value)}
                                                            value={tmsStatus}
                                                        >
                                                            <option value={"0"}>Select</option>
                                                            {bindCompanyStatusDropDown()}
                                                        </select>
                                                    </div>
                                                    <div className="col-md-6 mb-4">
                                                        <label
                                                            className="form-label font-14 font-semibold label-color form-text-color"
                                                            htmlFor="connectionstatus"
                                                        >Connection Status
                                                        </label>
                                                        <select
                                                            className="form-select custom-form-input secondary-bg-color form-field-border"
                                                            aria-label="Default select example"
                                                            id="connectionstatus"
                                                            style={{ cursor: 'pointer' }}
                                                            //PS_3PL_1.50
                                                            onChange={e => setConnectionStatus(e.target.value)}
                                                            value={connectionStatus}
                                                        >
                                                            <option value={"0"}>Select</option>
                                                            {bindConnectionStatusDropDown()}
                                                        </select>
                                                    </div>
                                                    <div className="col-md-12 mb-2">
                                                        <label className="font-16 font-semibold custom-header-color form-text-color">
                                                            Valid Till
                                                        </label>
                                                    </div>
                                                    <div className="col-md-6 mb-4">
                                                        <label
                                                            className="form-label font-14 font-semibold label-color form-text-color"
                                                            htmlFor="from"
                                                        >
                                                            From
                                                        </label>
                                                        <div className="position-relative">
                                                            <input className="form-control custom-form-input helper-text secondary-bg-color form-field-border" type="date" id="from"
                                                                placeholder="MM/DD/YYYY"
                                                                // max="9999-12-31"
                                                                value={tmsFilter.from_date}
                                                                max={tmsFilter.to_date}

                                                                onChange={e => setTMSFilter({ ...tmsFilter, from_date: e.target.value })}
                                                            />
                                                            <img className="position-absolute custom-input-icon cursor-pointer" src="/images/calander-icon.svg" alt="calander-icon"></img>

                                                            {/* <img src="images/calander-icon.svg" alt="calander-icon" className="position-absolute custom-input-eye-color custom-input-icon" /> */}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 mb-4">
                                                        <label
                                                            className="form-label font-14 font-semibold label-color form-text-color"
                                                            htmlFor="to"
                                                        >
                                                            To
                                                        </label>
                                                        <div className="position-relative">
                                                            <input className="form-control custom-form-input helper-text secondary-bg-color form-field-border" type="date" id="to"
                                                                placeholder="MM/DD/YYYY"
                                                                max="9999-12-31"
                                                                min={tmsFilter.from_date}
                                                                value={tmsFilter.to_date}
                                                                onChange={e => setTMSFilter({ ...tmsFilter, to_date: e.target.value })}
                                                            />
                                                            <img className="position-absolute custom-input-icon cursor-pointer" src="/images/calander-icon.svg" alt="calander-icon"></img>

                                                            {/* <img src="images/calander-icon.svg" alt="calander-icon" className="position-absolute custom-input-eye-color custom-input-icon" /> */}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="d-flex justify-content-end align-items-center py-3">
                                                            <button
                                                                className="cursor-pointer btn text-nowrap w-md-100 font-medium color-black-v1 font-14 d-flex gap-2 justify-content-center align-items-center py-2 me-3 px-4 form-text-color"
                                                                onClick={() => { handleClear(); setIsFilterOpen(!isFilterOpen); }}>
                                                                Cancel
                                                            </button>
                                                            <button

                                                                disabled={(!connectionStatus || connectionStatus === '0') && (!tmsStatus || tmsStatus === '0') && !tmsFilter.from_date && !tmsFilter.to_date}
                                                                className="cursor-pointer btn primary-btn text-nowrap  w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center px-4"
                                                                onClick={() => {
                                                                    retrieveTMSUsers();
                                                                    setIsFilterOpen(!isFilterOpen);
                                                                }}
                                                            >
                                                                Apply
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            { /*filter*/}
                                        </div>

                                        <button className="btn primary-btn text-nowrap  w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center"
                                            onClick={() => { navigate("/tms-creation") }}>
                                            <img src="images/add-icon-white.svg" alt="add-plus-icon"
                                            />
                                            Logistic Partner
                                        </button>
                                    </div>
                                </div>
                                <div className="table-responsive theme-table bg-white">
                                    <table className="table table-borderless mb-0">
                                        <thead>
                                            <tr>
                                                <th className="text-nowrap"
                                                    id="tms_name"
                                                >
                                                    Company Name
                                                    <span className="ms-1 cursor-pointer">
                                                        <><img src="images/sort-dark-up-arrow (1).svg" id="tms_name@desc" alt="sort-arrow-up" className="cust-sort-arrow-color" hidden={
                                                            sortHide === "tms_name@asc" || "" ? false : true
                                                        } onClick={e => { handleSort(e) }} /></>
                                                        <><img src="images/sort-down-arrow-white.svg" id="tms_name@asc" alt="sort-arrow-down" className="cust-sort-arrow-color" hidden={
                                                            sortHide === "tms_name@asc" || "" ? true : false
                                                        } onClick={e => { handleSort(e) }} /></>
                                                    </span>
                                                </th>
                                                <th className="text-nowrap"
                                                    id="user_name"
                                                >
                                                    Username
                                                    <span className="ms-1 cursor-pointer">
                                                        <><img src="images/sort-dark-up-arrow (1).svg" id="user_name@desc" alt="sort-arrow-up" className="cust-sort-arrow-color" hidden={
                                                            sortHide === "user_name@asc" || "" ? false : true
                                                        } onClick={e => { handleSort(e) }} /></>
                                                        <><img src="images/sort-down-arrow-white.svg" id="user_name@asc" alt="sort-arrow-down" className="cust-sort-arrow-color" hidden={
                                                            sortHide === "user_name@asc" || "" ? true : false
                                                        } onClick={e => { handleSort(e) }} /></>
                                                    </span>
                                                </th>
                                                <th className="text-nowrap"
                                                    id="businessEmailAddress">
                                                    Business Email Address
                                                    <span className="ms-1 cursor-pointer">
                                                    </span>
                                                </th>
                                                <th className="text-nowrap"
                                                >Phone #</th>
                                                <th className="text-nowrap">
                                                    Status
                                                    <span className="ms-1 cursor-pointer">
                                                        <><img src="images/sort-dark-up-arrow (1).svg" id="status@desc" alt="sort-arrow-up" className="cust-sort-arrow-color" hidden={
                                                            sortHide === "status@asc" || "" ? false : true
                                                        } onClick={e => { handleSort(e) }} /></>
                                                        <><img src="images/sort-down-arrow-white.svg" id="status@asc" alt="sort-arrow-down" className="cust-sort-arrow-color" hidden={
                                                            sortHide === "status@asc" || "" ? true : false
                                                        } onClick={e => { handleSort(e) }} /></>
                                                    </span>
                                                </th>
                                                <th className="text-nowrap">
                                                    Connection Status
                                                    <span className="ms-1 cursor-pointer">
                                                        <><img src="images/sort-dark-up-arrow (1).svg" id="tms_connection_status@desc" alt="sort-arrow-up" className="cust-sort-arrow-color" hidden={
                                                            sortHide === "tms_connection_status@asc" || "" ? false : true
                                                        } onClick={e => { handleSort(e) }} /></>
                                                        <><img src="images/sort-down-arrow-white.svg" id="tms_connection_status@asc" alt="sort-arrow-down" className="cust-sort-arrow-color" hidden={
                                                            sortHide === "tms_connection_status@asc" || "" ? true : false
                                                        } onClick={e => { handleSort(e) }} /></>
                                                    </span>
                                                </th>
                                                <th className="text-nowrap" >
                                                    Valid Till
                                                    <span className="ms-1 cursor-pointer">
                                                        <><img src="images/sort-dark-up-arrow (1).svg" id="validity_till@desc" alt="sort-arrow-up" className="cust-sort-arrow-color" hidden={
                                                            sortHide === "validity_till@asc" || "" ? false : true
                                                        } onClick={e => { handleSort(e) }} /></>
                                                        <><img src="images/sort-down-arrow-white.svg" id="validity_till@asc" alt="sort-arrow-down" className="cust-sort-arrow-color" hidden={
                                                            sortHide === "validity_till@asc" || "" ? true : false
                                                        } onClick={e => { handleSort(e) }} /></>
                                                    </span>
                                                </th>
                                                <th className="text-nowrap text-center">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* PS_3PL_1.19*/}
                                            {organizationDetails?.length > 0 ? bind3plGrid() : (noResults ? <NoResultsFound /> : (notFound ? <NoDataFound /> : null))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="mt-3 d-flex justify-content-between">
                                    <p className="mb-0 font-14 font-regular color-grey-v1 ">
                                        # of Records :
                                        <span className="color-black-v1 font-semibold font-18 primary-header-v1">
                                            {/* PS_3PL_1.53 */}
                                            {(10 + (tmsFilter?.start_index) < totalCount) ? ` ${10 + (tmsFilter?.start_index)} ` : ` ${totalCount} `}
                                        </span>
                                        out of
                                        <span className="color-black-v1 font-semibold font-18 primary-header-v1">
                                            {` ${totalCount} `}
                                        </span>
                                    </p>
                                    {totalPages > 1 ? <>
                                        <PaginationComponent currentPage={tmsFilter?.start_index / tmsFilter.page_size} viewCount={tmsFilter.page_size} totalCount={totalCount} onPageChange={handlePageClick} /> </> : <></>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default TMSDetails;


