import * as FileSaver from 'file-saver'
import { jwtDecode } from "jwt-decode"
import _ from "lodash"
import { useEffect, useState } from "react"
import * as XLSX from 'xlsx'
import { InvitationStatusDisplayText, RoleEnum } from "../../enums/enums"
import { AuditResponse, InsertErrorObj, AddUserRequest, ManageUser, UpdateObj, CompanyOptions } from "../../interface/Interface"
import { DropDownOptions } from '../../interface/LoadInterface'
import { ReportFilter } from "../../interface/ReportsInterface"
import { getCompanies, getUserRoles, getUsersDetails, insertUserDetail, updateUserDetail } from "../../service/Api"
import { getAuditLogApi } from "../../service/ReportsAPI"
import { getTimestampToDate } from "../../utils/Formatting"
import { isValidEmail } from "../../utils/utils"
import AuditHistory from '../TMSReports/AuditHistory'
import Loader from "../Resuables/Loader"
import NoDataFound from "../Resuables/NoDataFound"
import NoResultsFound from "../Resuables/NoResultsFound"
import PaginationComponent from "../Resuables/PaginationComponent"
import { SuccessToast } from "../Resuables/SuccessToast"
import TimeFrame from "../Resuables/TimeFrame"


function ManageUsers() {
    //PS_1.3
    let filterObj: ReportFilter = {
        search_data: "",
        from_date: "",
        to_date: "",
        sort_column: "",
        sort_type: "",
        start_index: 1,
        page_size: 10
    }
    //PS_2.2
    let newUserObj: AddUserRequest = {
        name: "",
        email_id: "",
        role_id: 0,
        role_name: "",
        tms_id: Number(localStorage.getItem("tmsId") || 0),
    }

    let insertErrorObj: InsertErrorObj = {
        name: "",
        emailId: "",
        role: ""
    }

    let UpdateObj: UpdateObj = {
        user_id: 0,
        email_id: "",
        current_role_name: "",
        role_id: 0,
        updated_role_name: "",
        is_active: null
    }

    //PS_1.4
    const [manageUserFilter, setManageUserFilter] = useState<ReportFilter>(filterObj);
    const [tmsId, setTMSId] = useState<number>(Number(localStorage.getItem("tmsId") || 0));
    //PS_1.5
    const [userDetails, setUserDetails] = useState<ManageUser[]>([]);
    //PS_1.6
    const [loader, setLoader] = useState(false);
    //PS_1.7
    const [errorPopup, setErrorPopup] = useState(false)
    //PS_1.8
    const [totalCount, setTotalCount] = useState<number>(0);
    //PS_2.3
    const [insertUser, setInsertUser] = useState<AddUserRequest>(newUserObj)
    const [insertError, setInsertError] = useState<InsertErrorObj>(insertErrorObj)

    const [updateUser, setUpdateUser] = useState<UpdateObj>(UpdateObj)
    const [update, setUpdate] = useState<boolean[]>([]);
    const [userDropDown, setUserDropdown] = useState<DropDownOptions[]>([])
    const [updateError, setUpdateError] = useState("");
    const [ToastMessage, setToastMessage] = useState("")
    const [noDataFound, setNoDataFound] = useState(false)
    const [noResultsFound, setNoResultsFound] = useState(false)
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [company, setCompany] = useState<CompanyOptions[]>([]);

    const [currentReportsTab, setCurrentReportsTab] = useState({
        manageUsers: true,
        auditHistory: false
    })

    const [auditHistory, setAuditHistory] = useState<AuditResponse[]>([]);

    //PSAF_01 - 02
    const [temporarySearch, setTemporarySearch] = useState("");
    const [isActive, setIsActive] = useState("");
    const [flag, setflag] = useState<boolean>(true)

    const accessToken = localStorage.getItem("accessToken") || "";
    let decodedToken = jwtDecode(accessToken) as { [key: string]: any };
    let groups = decodedToken["cognito:groups"];
    let etmLog: Boolean = false;
    if (groups[0] == RoleEnum.ETM_ADMIN && localStorage.getItem("isImpersonate") != "true") {
        etmLog = true;
    }

    useEffect(() => {
        if (ToastMessage != "") {
            const timer = setTimeout(() => {
                setToastMessage(""); // Hide the toast message after 10 seconds
            }, 3000);

            return () => clearTimeout(timer); // Clear the timer on component unmount or when openToastMessage changes
        }
    }, [ToastMessage]);

    //PS_1.9
    useEffect(() => {
        if (currentReportsTab.manageUsers) {
            getDropDown()
            getUsers()
        } else if (currentReportsTab.auditHistory) {
            //PS_05
            if (groups[0] == RoleEnum.ETM_ADMIN && localStorage.getItem("isImpersonate") != "true" && flag) {
                //SQ_H_1.7
                getCompany()
            } if (tmsId != 0) {
                getAuditHistory()

            }
        }
    }, [manageUserFilter, currentReportsTab, tmsId])

    useEffect(() => {
        if (updateUser.is_active !== null) {
            updateUserDetails();
        }
    }, [updateUser.is_active]);
    //PS_1.12 - 1.37
    async function getUsers() {
        try {
            setLoader(true)
            let result = await getUsersDetails((Number(localStorage.getItem("tmsId") || 0)), manageUserFilter, isActive)
            if (result?.data?.code == 200) {
                setUserDetails(result?.data?.data)
                setTotalCount(result?.data?.record_count || 0)
                if (result?.data?.record_count == 0) {
                    setUserDetails([])
                    setTotalCount(0)
                    if (_.isEqual(manageUserFilter, filterObj)) {
                        setNoDataFound(true)
                        setNoResultsFound(false)

                    } else {
                        setNoDataFound(false)
                        setNoResultsFound(true)
                    }
                } else {
                    setNoDataFound(false);
                    setNoResultsFound(false);
                }
            } else {
                setNoDataFound(true)
                setUserDetails([])
                setTotalCount(0)
                setErrorPopup(true)
            }
        }
        catch (error) {
            console.log(error);
        }
        finally {
            setLoader(false);
        }
    }
    //SQ_H_1.7 - 1.15
    const getCompany = async () => {
        try {
            let response = await getCompanies()
            if (response?.data?.code == 200) {
                setCompany(response?.data?.data);
                setflag(false)
                if (response?.data?.data.length > 0) {
                    let selectedTmsId = response?.data?.data[0]?.tms_id;
                    setTMSId(Number(selectedTmsId))
                }
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    //SQ_EX_2.16 - 2.17  PS_17 - 18
    const timeFrameOnChange = (startDate: Date | "", endDate: Date | "") => {
        setManageUserFilter((prevState) => ({
            ...prevState,
            from_date: startDate === "" ? "" : getTimestampToDate(startDate.toISOString(), 2) || "", // Ensure a string is always returned
            to_date: endDate === "" ? "" : getTimestampToDate(endDate.toISOString(), 2) || "", // Ensure a string is always returned
        }));
    };

    //PS_2.21 - 2.37
    async function insertUserDetails() {
        try {
            //PS_2.22
            setLoader(true);
            //PS_2.23
            /*Declared on object insertUserDetailsReq and
            add the details of the data that is to be sent as parameter.
            and call the api insertUserDetail with insertUserDetailsReq as parameter.*/
            const insertUserDetailsReq: AddUserRequest = {
                name: insertUser?.name.trim() || "",
                email_id: insertUser?.email_id.trim() || "",
                role_id: insertUser?.role_id || 0,
                role_name: insertUser?.role_name || "",
                tms_id: Number(localStorage.getItem("tmsId") || 0),
            }
            let result = await insertUserDetail(insertUserDetailsReq)
            if (result?.data?.code == 201) {
                getUsers()
                setInsertUser(newUserObj);
                setToastMessage(`User Inserted Successfully`)
            } else {
                let error: string = result?.data?.message || ""
                if (error.includes("UsernameExistsException")) {
                    setInsertError(prevstate => ({
                        ...prevstate,
                        emailId: "Email address already exists"
                    }));
                } else {
                    setErrorPopup(true)
                }
            }
        }
        catch (error) {
            console.log(error)
        }
        finally {
            setLoader(false)
        }
    }

    async function getDropDown() {
        try {
            setLoader(true);
            const tmsId: number = Number(localStorage.getItem("tmsId") || 0);
            let response = await getUserRoles(tmsId)
            if (response.data.code === 200) {
                setUserDropdown(response?.data?.data)
            }
            else {
                setUserDropdown([])
            }
        }
        catch (error) {
            console.log(error);
        }
        finally {
            setLoader(false);
        }
    }

    const getAuditHistory = async () => {
        setLoader(true);
        try {
            const result = await getAuditLogApi(tmsId, manageUserFilter, etmLog);
            if (result?.data?.code === 200) {
                const auditData = result?.data?.data || [];
                setAuditHistory(auditData);
                setTotalCount(result?.data?.record_count || 0);

                if (auditData.length === 0) {
                    if (JSON.stringify(manageUserFilter) === JSON.stringify(filterObj)) {
                        setNoDataFound(true);
                        setNoResultsFound(false);
                    } else {
                        setNoDataFound(false);
                        setNoResultsFound(true);
                    }
                } else {
                    setNoDataFound(false);
                    setNoResultsFound(false);
                }
            } else {
                setAuditHistory([]);
                setTotalCount(0);
                setNoDataFound(true);
                setNoResultsFound(false);
                setErrorPopup(true);
            }
        } catch (error) {
            console.error('Error fetching audit history:', error);
            setAuditHistory([]);
            setTotalCount(0);
            setNoDataFound(true);
            setNoResultsFound(false);
            setErrorPopup(true);
        } finally {
            setLoader(false);
        }
    };

    function buildDropDown() {
        try {
            return userDropDown?.map((role, index) => {
                return (
                    <option key={index} value={role.code} data-display-text={role.display_text}>
                        {role.display_text === RoleEnum.ETM_ADMIN || role.display_text === RoleEnum.THIRD_PARTY_ADMIN ? "Admin" : "Staff"}
                    </option>
                )
            })
        } catch (error) {
            console.log(error)
        }
    }

    function buildUsers() {
        try {

            return userDetails?.map((user, index) => (
                <tr key={index}>
                    <td className="text-nowrap">{user?.user_name}</td>
                    <td className="text-nowrap">{user?.email_id}</td>
                    <td className="text-nowrap">
                        {update[index] === true ? (
                            <>
                                <select
                                    value={updateUser?.role_id}
                                    onChange={(event) => {
                                        const selectedOption = event.target.options[event.target.selectedIndex];
                                        setUpdateUser(prevState => ({
                                            ...prevState,
                                            role_id: Number(selectedOption.value),
                                            updated_role_name: selectedOption.dataset.displayText || ""
                                        }))
                                    }}
                                    className="form-select custom-form-input custom-select-arrow primary-border-color form-drop"
                                    aria-label="Default select example"
                                >
                                    <option value={0}>Select</option>
                                    {buildDropDown()}
                                </select>
                                <div className="pt-1">
                                    <label className="required-color font-12 font-regular">{updateError}</label>
                                </div>
                            </>
                        ) : (
                            <>
                                <span>{user?.role_name == RoleEnum.ETM_ADMIN || user?.role_name == RoleEnum.THIRD_PARTY_ADMIN ? "Admin" : "Staff"}</span>
                            </>
                        )}
                    </td>
                    <td className="text-nowrap">
                        <div className="form-check form-switch">
                            <input
                                className="form-check-input custom-switch-input custom-toggle"
                                type="checkbox"
                                id={`flexSwitchCheckDefault${index}`}
                                checked={user?.is_active}
                                disabled={user.is_owner}
                                onChange={(e) => {
                                    //PS_17.0-17.4
                                    setUpdateUser(prevstate => ({
                                        ...prevstate,
                                        user_id: user.user_id,
                                        email_id: user.email_id,
                                        current_role_name: user.role_name,
                                        is_active: e.target.checked
                                    }));
                                }}
                            />
                            <label
                                className="form-check-label"
                                htmlFor={`flexSwitchCheckDefault${index}`}                        >
                                {user.is_active ? 'Yes' : 'No'}
                            </label>
                        </div>
                    </td>
                    <td className="text-nowrap">{InvitationStatusDisplayText[user?.invitation_status]}</td>
                    <td className="text-center">
                        {update[index] === true ? (
                            <div>
                                <button
                                    type="button"
                                    className="border-0 px-3 bg-transparent"
                                    onClick={() => {//PS_19.0
                                        updateValidation()
                                    }}
                                >
                                    <img src="images/tick-icon 1.svg" alt="Tick" title="Tick" />
                                </button>
                                <button
                                    type="button"
                                    className="border-0 px-3 bg-transparent"
                                    onClick={() => { setUpdateUser(UpdateObj); setUpdate([]); setUpdateError("") }}
                                >
                                    <img src="images/close-icon 1.svg" alt="Cancel" title="Cancel" />
                                </button>
                            </div>
                        ) : (
                            <button disabled={user.is_owner}
                                type="button"
                                className="border-0 px-3 bg-transparent"
                                onClick={() => {
                                    let updateView: boolean[] = [];
                                    updateView[index] = true
                                    setUpdate(updateView)
                                    setUpdateUser(prevstate => ({
                                        ...prevstate,
                                        user_id: user.user_id,
                                        email_id: user.email_id,
                                        role_id: user.role_id,
                                        current_role_name: user.role_name,
                                    }));
                                }}
                            >
                                <img src="images/edit-icon.svg" alt="Edit" title="Edit" />
                            </button>
                        )}
                    </td>
                </tr>
            ));
        } catch (error) {
            console.log(error);
        }
    }

    const handleSortClick = (column: string) => {
        setManageUserFilter((prevState) => {
            if (prevState.sort_column === column) {
                // If the same column is clicked again, toggle the sort order
                return {
                    ...prevState,
                    sort_column: column,
                    sort_type: prevState.sort_type === 'asc' ? 'desc' : 'asc',
                    start_index: 1
                };
            } else {
                // If a different column is clicked, reset the sort order to 'asc'
                return {
                    ...prevState,
                    sort_column: column,
                    sort_type: 'asc',
                    start_index: 1
                };
            }
        });
    };

    //PS_13-16
    const handlePageClick = (data: { selected: number }) => {
        setManageUserFilter(prevState => ({
            ...prevState,
            start_index: (data.selected * manageUserFilter?.page_size) + 1,
        }));
    }

    const renderSortArrow = (column: string) => {
        if (manageUserFilter.sort_column === column) {
            return manageUserFilter.sort_type === 'asc' ? (
                <img src="images/sort-dark-up-arrow (1).svg" alt="sort-arrow-up" className="cust-sort-arrow-color" />
            ) : (
                <img src="images/sort-down-arrow-white.svg" alt="sort-arrow-down" className="cust-sort-arrow-color" />
            );
        }
        return <img src="images/sort-down-arrow-white.svg" alt="sort-arrow-down" className="cust-sort-arrow-color" />;
    };
    //PS_2.7-2.19
    const validation = () => {
        let count = 0;
        let errors = insertErrorObj;
        // Validation for username
        if (!insertUser.name) {
            errors.name = "Please enter username";
            count++
        }

        // Validation for email
        if (!insertUser.email_id.trim() || !isValidEmail(insertUser.email_id.trim())) {
            errors.emailId = "Please enter valid email address";
            count++
        }

        // Validation for roleId
        if (insertUser.role_id == 0) {
            errors.role = "Please select role";
            count++
        }
        setInsertError(errors);
        if (count == 0) {
            insertUserDetails()
        }
    }
    //PS_3.6 -3.10
    const updateValidation = () => {
        let count = 0;
        let updateValidate = ""
        // Validation for roleId
        if (updateUser.role_id == 0) {
            updateValidate = "Please enter role";
            count++
        }
        setUpdateError(updateValidate);
        if (count == 0) {
            updateUserDetails()
        }
    }
    //PS_3.10 - 3.22
    async function updateUserDetails() {
        try {
            /*Declared on object updateUserDetailsReq and
            add the details of the data that is to be sent as parameter.
            and call the api updateUserDetailsReq with insertUserDetailsReq as parameter.*/
            setLoader(true)
            let result = await updateUserDetail(updateUser)
            if (result?.data?.code == 200) {
                getUsers();
                setToastMessage(`User Updated Successfully`)
            } else {
                setErrorPopup(true);
            }
        }
        catch (error) {
            console.log(error)
        }
        finally {
            setLoader(false)
            setUpdateUser(UpdateObj);
            setUpdate([])
        }
    }

    const exportAuditReports = async () => {
        setLoader(true);
        try {
            const modifiedUsersFilter = { ...manageUserFilter, start_index: 0, page_size: 0 };
            const result = await getAuditLogApi(tmsId, modifiedUsersFilter, etmLog);

            if (result?.data?.code === 200) {
                const data: AuditResponse[] = result?.data?.data || [];

                const headers = ["Username", "Role", ...(etmLog ? ["Company Name"] : []), "Load Id", "TMS Load Number", "Time stamp", "Log"];
                let exportToExcel: string[][] = [headers];

                data.forEach((value: AuditResponse) => {
                    const formattedRow: string[] = [
                        value.user_name,
                        value.role,
                        ...(etmLog ? [value.tms_name] : []),
                        value.load_id.toString(),
                        value.tms_load_number,
                        value.timestamp_utc,
                        value.log
                    ];
                    exportToExcel.push(formattedRow);
                });

                const filename = `Audit_History_${new Date().toISOString().replace(/[-:]/g, '').split('.')[0]}`;
                const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                const fileExtension = '.xlsx';

                const ws = XLSX.utils.aoa_to_sheet(exportToExcel);
                const wb = { Sheets: { 'Audit Log': ws }, SheetNames: ['Audit Log'] };
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const data1 = new Blob([excelBuffer], { type: fileType });
                FileSaver.saveAs(data1, filename + fileExtension);
            } else {
                console.error('Failed to fetch audit report data');
                // Optionally, you can show an error message to the user here
            }
        } catch (error) {
            console.error('Error exporting audit reports:', error);
            // Optionally, you can show an error message to the user here
        } finally {
            setLoader(false);
        }
    };

    return (

        <>
            {loader ? <><Loader /></> : <></>}
            {ToastMessage ?
                <div>
                    {SuccessToast(ToastMessage)}
                </div> : <></>
            }
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 fixedhd">
                        <div className="px-4 py-4 mb-3 border-0 rounded-3">
                            <div className="d-flex align-items-center justify-content-between mb-4 flex-wrap gap-3 ">

                                <ul className="nav nav-tabs border-0" id="myTab" role="tablist">
                                    <li className="nav-item cust-nav-item" role="presentation">
                                        <button onClick={() => {
                                            setCurrentReportsTab({
                                                manageUsers: true,
                                                auditHistory: false,
                                            });
                                            setManageUserFilter(filterObj);
                                            setflag(true)
                                            setIsActive("");
                                            setTemporarySearch("");
                                        }}
                                            className="nav-link cus-tab-link cus-tab-border color-grey border-0 ps-0 font-semibold font-14 nav-bg active"
                                            id="mangeusers-tab" data-bs-toggle="tab" data-bs-target="#mangeusers" type="button"
                                            role="tab" aria-controls="mangeusers" aria-selected="true">Manage Users</button>
                                    </li>
                                    <li className="nav-item cust-nav-item" role="presentation">
                                        <button onClick={() => {
                                            setCurrentReportsTab({
                                                manageUsers: false,
                                                auditHistory: true,
                                            });
                                            setManageUserFilter(filterObj);
                                            setIsActive("");
                                            setTemporarySearch("");
                                            setIsFilterOpen(false)
                                        }}
                                            className="nav-link cus-tab-link cus-tab-border border-0 color-grey font-semibold font-14 nav-bg"
                                            id="audithistory-tab" data-bs-toggle="tab" data-bs-target="#audithistory"
                                            type="button" role="tab" aria-controls="audithistory" aria-selected="false">Audit History</button>
                                    </li>
                                </ul>

                                <div className="d-flex gap-3">

                                    {groups[0] == RoleEnum.ETM_ADMIN && localStorage.getItem("isImpersonate") != "true" && currentReportsTab.auditHistory ?
                                        <div className="form-floating">
                                            <select className="form-select custom-form-input select-filter custom-select-arrow font-12"
                                                id="floatingSelect" aria-label="Floating label select example" value={tmsId}
                                                onChange={(e) => {
                                                    //PS-4.0 -4.2
                                                    setTMSId(Number(e.target.value))
                                                }}>
                                                {company && company?.length === 0 ? (
                                                    <option value="0">Select</option>
                                                ) : (
                                                    company?.map((companyItem) => (
                                                        <option key={companyItem?.tms_id} value={companyItem?.tms_id}>
                                                            {companyItem?.name}
                                                        </option>
                                                    ))
                                                )}
                                            </select>
                                            <label className="font-11 top-nav-label-color pt-3 pb-0 font-regular text-nowrp"
                                                htmlFor="floatingSelect">Company name</label>
                                        </div> : <></>}
                                    {currentReportsTab.auditHistory ? <TimeFrame onChange={timeFrameOnChange} /> : <></>}
                                    <div className="input-group input-search custom-reports-search">
                                        <input value={temporarySearch} onChange={(e) => {
                                            //PSAF_15
                                            setTemporarySearch(e.target.value.trimStart())
                                        }} type="search" className="form-control border-0 font-14 font-regular primary-bg-color"
                                            onKeyDown={e => {
                                                //PSAF_04
                                                if (e.key === "Enter") {
                                                    setManageUserFilter(prevState => ({
                                                        ...prevState,
                                                        search_data: temporarySearch.trim(),
                                                        start_index: 1
                                                    }));
                                                }
                                            }}
                                            placeholder="Search" ></input>
                                        <button className="btn border-0 search-input-btn primary-bg-color" type="button"><img
                                            src="images/search-icon.svg" alt="search" onClick={() => {
                                                setManageUserFilter(prevState => ({
                                                    ...prevState,
                                                    search_data: temporarySearch.trim(),
                                                    start_index: 1
                                                }));
                                            }} /></button>
                                    </div>
                                    <div className="dropdown">
                                        {currentReportsTab.manageUsers ? <button
                                            className={isFilterOpen ? "btn filter-btn border-0 custom-border-box-shadow primary-bg-color show" : "btn filter-btn border-0 custom-border-box-shadow primary-bg-color"}
                                            aria-expanded="true"
                                            onClick={() => {
                                                setIsFilterOpen(!isFilterOpen);
                                            }}>
                                            <img src="images/filter-icon.svg" alt="filter-icon" />
                                        </button> : <></>}
                                        {currentReportsTab.auditHistory ? <button disabled={!(totalCount > 0)} className="btn export-btn border-0 custom-border-box-shadow primary-bg-color px-3" onClick={() => { exportAuditReports() }}>
                                            <span className="text-nowrap export-button" ><img src="images/export-image.svg" alt="export-img" className="me-2 mb-1" />Export</span>
                                        </button> : <></>}
                                        <div
                                            className={isFilterOpen ? "dropdown-menu dropdown-menu-end top-40 border-0 shadow p-3 custom-filter-width secondary-bg-color show" : "dropdown-menu dropdown-menu-end border-0 shadow dd-position p-3 custom-filter-width secondary-bg-color"}
                                            aria-labelledby="dropdownMenuLink"
                                        >
                                            <div className="d-flex justify-content-between align-items-center mb-4">
                                                <h5 className="font-bold font-20 mb-0 form-text-color">Advanced Filter</h5>
                                                <span><img className="cursor-pointer close-img" src="images/cross-icon-black.svg"
                                                    alt="close" onClick={() => {
                                                        setIsFilterOpen(false);
                                                    }} /></span>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-12 mb-4">
                                                    <label className="form-label font-14 font-semibold label-color form-text-color " htmlFor="Status">Is
                                                        Active</label>
                                                    <select onChange={(e) => {
                                                        setIsActive(e.target.value);
                                                    }}
                                                        className="form-select custom-form-input primary-bg-color custom-select-arrow primary-border-color"
                                                        aria-label="Default select example" value={isActive}>
                                                        <option value="">Select</option>
                                                        <option value="true">Yes</option>
                                                        <option value="false">No</option>
                                                    </select>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="d-flex justify-content-end align-items-center py-3 ">
                                                        <button className="btn text-nowrap w-md-100 font-medium color-black-v1 font-14 d-flex gap-2 justify-content-center align-items-center py-2 me-3 px-4" onClick={() => {
                                                            setIsFilterOpen(false);
                                                            //PSAF_13
                                                            setManageUserFilter(prevState => ({
                                                                ...prevState, // Spread the old state
                                                                search_data: "", // Reset the search to an empty string
                                                                start_index: 1, // Reset startIndex to 0
                                                                sort_column: "", // Clear the sort column
                                                                sort_type: "desc" // Set the sort type to "desc"
                                                            }));
                                                            setIsActive("");
                                                            setTemporarySearch("");
                                                        }}> Cancel</button>
                                                        <button
                                                            disabled={isActive == ""}
                                                            className={"btn primary-btn text-nowrap  w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center px-4"}
                                                            onClick=
                                                            {//PSAF_11
                                                                () => {
                                                                    //PS-4.0 -4.2
                                                                    setManageUserFilter(prevState => ({
                                                                        ...prevState,
                                                                        start_index: 1
                                                                    }));
                                                                    setIsFilterOpen(false);
                                                                }}>Apply</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="mangeusers" role="tabpanel"
                                    aria-labelledby="mangeusers-tab">

                                    <div className="table-responsive theme-table bg-white ">
                                        <table className="table table-borderless mb-0">
                                            <thead>
                                                <tr>
                                                    {// //PS_7.2
                                                    }
                                                    <th className="text-nowrap cursor-pointer" onClick={() => handleSortClick('user_name')}>
                                                        Username<span className="ms-1">{renderSortArrow('user_name')}</span>
                                                    </th>
                                                    <th className="text-nowrap cursor-pointer" onClick={() => handleSortClick('email_address')}>
                                                        Email Address<span className="ms-1">{renderSortArrow('email_address')}</span>
                                                    </th>
                                                    <th className="text-nowrap cursor-pointer" onClick={() => handleSortClick('role_name')}>
                                                        Role<span className="ms-1">{renderSortArrow('role_name')}</span>
                                                    </th>
                                                    <th className="text-nowrap">Is Active</th>
                                                    <th className="text-nowrap cursor-pointer" onClick={() => handleSortClick('invitation_status')}>
                                                        Status<span className="ms-1">{renderSortArrow('invitation_status')}</span>
                                                    </th>
                                                    <th className="text-nowrap text-center">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="text-nowrap">
                                                        <input
                                                            type="text"
                                                            maxLength={64}
                                                            className="form-control custom-form-input primary-bg-color primary-border-color form-drop"
                                                            placeholder="Enter Username"
                                                            onChange={(e) => {
                                                                const inputValue = e.target.value;
                                                                // If the current name is empty and the new input is just spaces, don't update
                                                                if (insertUser.name === '' && inputValue.trim() === '') {
                                                                    return;
                                                                }
                                                                setInsertUser((prevState) => ({
                                                                    ...prevState,
                                                                    name: e.target.value.trimStart(),
                                                                }));
                                                            }}
                                                            value={insertUser.name}
                                                        />
                                                        <div className="pt-1">
                                                            <label className="required-color font-12 font-regular">{insertError.name}</label>
                                                        </div>
                                                    </td>
                                                    <td className="text-nowrap">
                                                        <input value={insertUser.email_id} type="text" maxLength={64}
                                                            className="form-control custom-form-input primary-bg-color primary-border-color form-drop"
                                                            placeholder="Enter Email Address" onChange={(e) => {
                                                                const inputValue = e.target.value;
                                                                // If the current name is empty and the new input is just spaces, don't update
                                                                if (insertUser.email_id === '' && inputValue.trim() === '') {
                                                                    return;
                                                                }
                                                                setInsertUser((prevState) => ({
                                                                    ...prevState,
                                                                    email_id: e.target.value.trim(),
                                                                }));
                                                            }}></input>
                                                        <div className="pt-1">
                                                            <label className="required-color font-12 font-regular">{insertError.emailId}</label>
                                                        </div>
                                                    </td>
                                                    <td className="text-nowrap">
                                                        <select value={insertUser?.role_id} onChange={(event) => {
                                                            const selectedOption = event.target.options[event.target.selectedIndex];

                                                            // Update insertUser state
                                                            setInsertUser(prevState => ({
                                                                ...prevState,
                                                                role_id: Number(selectedOption.value),
                                                                role_name: selectedOption.dataset.displayText || ""
                                                            }))
                                                        }}
                                                            className="form-select custom-form-input custom-select-arrow primary-border-color form-drop"
                                                            aria-label="Default select example">
                                                            <option value={0} selected>Select</option>
                                                            {buildDropDown()}
                                                        </select>
                                                        <div className="pt-1">
                                                            <label className="required-color font-12 font-regular">{insertError.role}</label>
                                                        </div>
                                                    </td>
                                                    <td className="text-nowrap"></td>
                                                    <td className="text-nowrap"></td>
                                                    <td className="text-center">
                                                        <button type="button" className="border-0 m-0 px-3 bg-transparent" onClick={() => { validation() }}>
                                                            <img src="images/red-plus-icon.svg" alt="Add" title="Add"></img>
                                                        </button>
                                                    </td>
                                                </tr>
                                                {buildUsers()}
                                                {noDataFound ? <NoDataFound /> : <></>}
                                                {noResultsFound ? <NoResultsFound /> : <></>}
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                                <div className={currentReportsTab.auditHistory ? "tab-pane fade show active" : "tab-pane fade"} id="audithistory" role="tabpanel" aria-labelledby="audithistory-tab">
                                    <AuditHistory Groups={groups} Data={auditHistory} renderSortArrow={renderSortArrow} handleSortClick={handleSortClick} NoData={noDataFound} NoResults={noResultsFound} />
                                </div>
                                <div className="mt-3 d-flex justify-content-between">
                                    <p className="mb-0 font-14 font-regular color-grey-v1 "># of Records :
                                        <span className="color-black-v1 font-semibold primary-header-v1 font-18">{(((manageUserFilter?.start_index - 1) + Number(manageUserFilter?.page_size)) < totalCount) ? ` ${((manageUserFilter?.start_index - 1) + Number(manageUserFilter?.page_size))} ` : ` ${totalCount} `} </span>out of
                                        <span className="color-black-v1 font-semibold primary-header-v1 font-18">{` ${totalCount}`}</span>
                                    </p>
                                    <div >
                                        {totalCount > manageUserFilter.page_size ?
                                            <PaginationComponent currentPage={(manageUserFilter.start_index - 1) / manageUserFilter?.page_size} viewCount={manageUserFilter?.page_size} totalCount={totalCount} onPageChange={handlePageClick} /> : <></>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default ManageUsers