import { useEffect, useState } from "react";
import { TrackingVendorCodes } from "../../enums/enums";
import { CarrierResponse } from "../../interface/Interface";
import { ReportFilter } from "../../interface/ReportsInterface";
import { getCarrierDetails } from "../../service/Api";
import Loader from "../Resuables/Loader";
import NoDataFound from "../Resuables/NoDataFound";
import NoResultsFound from "../Resuables/NoResultsFound";
import PaginationComponent from "../Resuables/PaginationComponent";
import { SomethingWentWrong } from "../Resuables/SomethingWentWrong";

let filterObj: ReportFilter = {
  search_data: "",
  from_date: "",
  to_date: "",
  sort_column: "carrier_name",
  sort_type: "ASC",
  start_index: 0,
  page_size: 10,
};

//PS_3PL_1.1
const CarrierInformation = () => {
  const [sortHide, setSortHide] = useState<string>("carrier_name@ASC");
  const [loader, setLoader] = useState(false);
  const tmsId: number = Number(localStorage.getItem("tmsId") || 0);
  //PS_3PL_1.2
  const [carrierGridRequest, setCarrierGridRequest] =
    useState<ReportFilter>(filterObj);

  const [noResults, setNoResults] = useState(false);
  //PS_3PL_1.3
  const [carrierResponse, setCarrierResponse] = useState<CarrierResponse[]>([]);
  //PS_3PL_1.4
  const [openSomethingWentWrongPopup, setOpenSomethingWentWrongPopup] =
    useState(false);

  //PS_3PL_1.4
  const [notFound, setNotFound] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selectedEldProvider, setSelectedEldProvider] = useState<string>("");
  const [searchInput, setSearchInput] = useState("");
  const [tempEldProvider, setTempEldProvider] = useState<string>("");

  //PS_3PL_1.7
  //use Effect to retireve data
  useEffect(() => {
    getCarrierGrid();
  }, [selectedEldProvider, carrierGridRequest]);

  const getCarrierGrid = async () => {
    try {
      setLoader(true);
      //PS_3PL_1.15
      const response = await getCarrierDetails(
        tmsId,
        carrierGridRequest,
        selectedEldProvider
      );
      //PS_3PL_1.17

      if (response.data.code === 200) {
        const carriers = response.data.data || [];
        setCarrierResponse(carriers);
        setTotalCount(response.data.record_count || 0);
        if (carriers.length === 0) {
          // No data found
          if (
            carrierGridRequest.search_data ||
            carrierGridRequest.sort_column ||
            carrierGridRequest.sort_type ||
            selectedEldProvider
          ) {
            // If there's a search or filter applied, show "No Results"
            setNoResults(true);
            setNotFound(false);
          } else {
            // If there's no search or filter, show "Not Found"
            setNotFound(true);
            setNoResults(false);
          }
        } else {
          // Data found
          setNotFound(false);
          setNoResults(false);
        }
      } else {
        // Error response
        setCarrierResponse([]);
        setTotalCount(0);
        setNotFound(true);
        setNoResults(false);
      }
      if (response.data.code == 404) {
        setNoResults(true);
      }
    } catch (e: any) {
      setOpenSomethingWentWrongPopup(true);
      setCarrierResponse([]);
      setTotalCount(0);
      setNotFound(true);
      setNoResults(false);
    } finally {
      setLoader(false);
    }
  };
  //PS_3PL_1.13

  const bindEldProviderDropDown = () => {
    return Object.entries(TrackingVendorCodes).map(([key, value]) => {
      if (value !== "TEXTLOCATE") {
        return (
          <option value={value} key={key}>
            {key}
          </option>
        );
      }
    });
  };

  const bindCarrierGrid = () => {
    //PS_3PL_1.22
    return carrierResponse?.map((obj: CarrierResponse) => {
      //PS_3PL_1.34
      return (
        <tr>
          <td className="text-nowrap">
            {obj?.carrier_name ? obj?.carrier_name : "-"}
          </td>
          <td className="text-nowrap">
            {obj?.carrier_id ? obj?.carrier_id : "-"}
          </td>
          <td className="text-nowrap">{obj?.email_id ? obj?.email_id : "-"}</td>
          <td className="text-nowrap">
            {obj?.mc_number ? obj?.mc_number : "-"}
          </td>
          <td className="text-nowrap">
            {obj?.dot_number ? obj?.dot_number : "-"}
          </td>
          <td className="text-nowrap">
            {obj?.vendor_name ? obj?.vendor_name : "-"}
          </td>
          <td className="text-nowrap">
            {obj?.truck_count ? obj?.truck_count : "-"}
          </td>
          <td className="text-nowrap">
            {obj?.trailer_count ? obj?.trailer_count : "-"}
          </td>
        </tr>
      );
    });
  };
  const handleSort = async (event: any) => {
    try {
      //PS_3PL_1.28
      const sortId = event.target.id;
      const sortValues = sortId.split("@");
      //PS_3PL_1.29
      const updatedCarrierRequest = {
        ...carrierGridRequest,
        start_index: 0,
        page_size: 10,
        sort_column: sortValues[0],
        sort_type: sortValues[1],
      };
      setCarrierGridRequest(updatedCarrierRequest);
      setSortHide(sortId);
    } catch (e: any) {
      console.log(e);
    }
  };

  const handleSearch = async () => {
    const updatedCarrierRequest = {
      ...carrierGridRequest,
      search_data: searchInput.trim(),
      start_index: 0,
    };
    setCarrierGridRequest(updatedCarrierRequest);
  };

  /*PS_46
Declare a function onClickApply with parameter as event
setState the value of isSaveEnabled and then set state the carrierGridRequest */
  const onChangeFilter = (event: any) => {
    setTempEldProvider(event.target.value);
  };

  /*PS_45 Define a function handleApplyFilter
Check if the selectedEldProvider is not equal to 0
If true,set State the carrierRequest along with eldProviderid
else, set state the defaultCarrierRequest */
  const handleApplyFilter = () => {
    setSelectedEldProvider(tempEldProvider);
    const updatedCarrierRequest = {
      ...carrierGridRequest,
      start_index: 0, // Reset to the first page when applying filter
    };
    setCarrierGridRequest(updatedCarrierRequest);
    setIsFilterOpen(false);
  };

  const handlePageClick = (data: { selected: number }) => {
    const pageUp = {
      ...carrierGridRequest,
      start_index: (data.selected * 10),
    };
    setCarrierGridRequest(pageUp);
  };

  const handleClear = () => {
    setSelectedEldProvider("");
    setTempEldProvider("");
    setIsFilterOpen(!isFilterOpen);
  };

  return (
    <>
      <div>
        {loader ? <Loader /> : <></>}
        {openSomethingWentWrongPopup ? (
          <SomethingWentWrong
            onClose={() => setOpenSomethingWentWrongPopup(false)}
          />
        ) : null}
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 fixedhd">
              <div className="px-4 py-4 mb-3 border-0 rounded-3">
                <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap gap-3 ">
                  <h5 className="mb-0 color-black-v1 font-22 font-bold">
                    Carriers
                  </h5>
                  <div className="d-flex gap-3 w-md-100">
                    <div className="input-group input-search">
                      <input
                        type="search"
                        className="form-control border-0 font-14 font-regular primary-bg-color"
                        placeholder="Search"
                        onKeyDown={(e) => {
                          if (e.key === "Enter") handleSearch();
                        }}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (searchInput === "" && inputValue.trim() === "") {
                            // If the input is empty and the new value is only spaces, don't update
                            return;
                          }
                          setSearchInput(e.target.value);
                        }}
                        value={searchInput}
                      />
                      <button
                        className="btn border-0 search-input-btn primary-bg-color"
                        type="button"
                        onClick={handleSearch}
                      >
                        <img src="images/search-icon.svg" alt="search" />
                      </button>
                    </div>

                    <div className="dropdown">
                      <button
                        className={
                          isFilterOpen
                            ? "btn filter-btn border-0 custom-border-box-shadow primary-bg-color show"
                            : "btn filter-btn border-0 custom-border-box-shadow primary-bg-color"
                        }
                        aria-expanded="true"
                        onClick={() => {
                          setIsFilterOpen(!isFilterOpen);
                        }}
                      >
                        <img src="images/filter-icon.svg" alt="filter-icon" />
                      </button>
                      {/*filter*/}
                      <div
                        className={
                          isFilterOpen
                            ? "dropdown-menu dropdown-menu-end border-0 shadow p-3 custom-filter-width carrier-custom-filter secondary-bg-color show top-40"
                            : "dropdown-menu dropdown-menu-end border-0 shadow dd-position p-3 custom-filter-width secondary-bg-color"
                        }
                        aria-labelledby="dropdownMenuLink"
                      >
                        <div className="d-flex justify-content-between align-items-center mb-4">
                          <h5 className="font-bold font-20 mb-0 custom-header-color form-text-color">
                            Advanced Filter
                          </h5>
                          <span>
                            <img
                              className="cursor-pointer left-arrow"
                              src="images/cross-icon-black.svg"
                              alt="close"
                              onClick={() => {
                                setIsFilterOpen(!isFilterOpen);
                                setIsFilterOpen(!isFilterOpen);
                              }}
                            />
                          </span>
                        </div>
                        <div className="col-md-12 mb-4">
                          <label
                            className="form-label font-14 font-semibold label-color form-text-color"
                            htmlFor="Status"
                          >
                            ELD Provider
                          </label>
                          <select
                            className="form-select custom-form-input secondary-bg-color form-field-border"
                            aria-label="Default select example"
                            style={{ cursor: "pointer" }}
                            value={tempEldProvider}
                            onChange={(e) => onChangeFilter(e)}
                          >
                            <option value={""}>Select</option>
                            {bindEldProviderDropDown()}
                          </select>
                        </div>
                        <div className="col-md-12">
                          <div className="d-flex justify-content-end align-items-center py-3">
                            <button
                              className="btn text-nowrap w-md-100 font-medium color-black-v1 font-14 d-flex gap-2 justify-content-center align-items-center py-2 me-3 px-4 form-text-color"
                              onClick={() => {
                                setIsFilterOpen(!isFilterOpen);
                                handleClear();
                              }}
                            >
                              Cancel
                            </button>

                            <button
                              disabled={tempEldProvider == ""}
                              className={
                                "btn primary-btn text-nowrap  w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center px-4"
                              }
                              onClick={() => {
                                handleApplyFilter();
                              }}
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="table-responsive theme-table bg-white">
                  <table className="table table-borderless mb-0">
                    <thead>
                      <tr>
                        <th className="text-nowrap" id="carrierName">
                          Name
                          <span className="ms-1 cursor-pointer">
                            <>
                              <img
                                src="images/sort-dark-up-arrow (1).svg"
                                id="carrier_name@DESC"
                                alt="sort-arrow-up"
                                className="cust-sort-arrow-color"
                                hidden={
                                  sortHide === "carrier_name@ASC" || ""
                                    ? false
                                    : true
                                }
                                onClick={(e) => {
                                  handleSort(e);
                                }}
                              />
                            </>
                            <>
                              <img
                                src="images/sort-down-arrow-white.svg"
                                id="carrier_name@ASC"
                                alt="sort-arrow-down"
                                className="cust-sort-arrow-color"
                                hidden={
                                  sortHide === "carrier_name@ASC" || ""
                                    ? true
                                    : false
                                }
                                onClick={(e) => {
                                  handleSort(e);
                                }}
                              />
                            </>
                          </span>
                        </th>
                        <th className="text-nowrap" id="username">
                          ID
                          <span className="ms-1 cursor-pointer">
                            <>
                              <img
                                src="images/sort-dark-up-arrow (1).svg"
                                id="carrier_id@DESC"
                                alt="sort-arrow-up"
                                className="cust-sort-arrow-color"
                                hidden={
                                  sortHide === "carrier_id@ASC" || ""
                                    ? false
                                    : true
                                }
                                onClick={(e) => {
                                  handleSort(e);
                                }}
                              />
                            </>
                            <>
                              <img
                                src="images/sort-down-arrow-white.svg"
                                id="carrier_id@ASC"
                                alt="sort-arrow-down"
                                className="cust-sort-arrow-color"
                                hidden={
                                  sortHide === "carrier_id@ASC" || ""
                                    ? true
                                    : false
                                }
                                onClick={(e) => {
                                  handleSort(e);
                                }}
                              />
                            </>
                          </span>
                        </th>
                        <th className="text-nowrap" id="businessEmailAddress">
                          Email Address
                          <span className="ms-1 cursor-pointer"></span>
                        </th>
                        <th className="text-nowrap">
                          MC #{" "}
                          <span className="ms-1 cursor-pointer">
                            <>
                              <img
                                src="images/sort-dark-up-arrow (1).svg"
                                id="mc_number@DESC"
                                alt="sort-arrow-up"
                                className="cust-sort-arrow-color"
                                hidden={
                                  sortHide === "mc_number@ASC" || ""
                                    ? false
                                    : true
                                }
                                onClick={(e) => {
                                  handleSort(e);
                                }}
                              />
                            </>
                            <>
                              <img
                                src="images/sort-down-arrow-white.svg"
                                id="mc_number@ASC"
                                alt="sort-arrow-down"
                                className="cust-sort-arrow-color"
                                hidden={
                                  sortHide === "mc_number@ASC" || ""
                                    ? true
                                    : false
                                }
                                onClick={(e) => {
                                  handleSort(e);
                                }}
                              />
                            </>
                          </span>
                        </th>
                        <th className="text-nowrap">
                          DOT #
                          <span className="ms-1 cursor-pointer">
                            <>
                              <img
                                src="images/sort-dark-up-arrow (1).svg"
                                id="dot_number@DESC"
                                alt="sort-arrow-up"
                                className="cust-sort-arrow-color"
                                hidden={
                                  sortHide === "dot_number@ASC" || ""
                                    ? false
                                    : true
                                }
                                onClick={(e) => {
                                  handleSort(e);
                                }}
                              />
                            </>
                            <>
                              <img
                                src="images/sort-down-arrow-white.svg"
                                id="dot_number@ASC"
                                alt="sort-arrow-down"
                                className="cust-sort-arrow-color"
                                hidden={
                                  sortHide === "dot_number@ASC" || ""
                                    ? true
                                    : false
                                }
                                onClick={(e) => {
                                  handleSort(e);
                                }}
                              />
                            </>
                          </span>
                        </th>
                        <th className="text-nowrap">
                          ELD Provider
                          <span className="ms-1 cursor-pointer">
                            <>
                              <img
                                src="images/sort-dark-up-arrow (1).svg"
                                id="vendor_name@DESC"
                                alt="sort-arrow-up"
                                className="cust-sort-arrow-color"
                                hidden={
                                  sortHide === "vendor_name@ASC" || ""
                                    ? false
                                    : true
                                }
                                onClick={(e) => handleSort(e)}
                              />
                            </>
                            <>
                              <img
                                src="images/sort-down-arrow-white.svg"
                                id="vendor_name@ASC"
                                alt="sort-arrow-down"
                                className="cust-sort-arrow-color"
                                hidden={
                                  sortHide === "vendor_name@ASC" || ""
                                    ? true
                                    : false
                                }
                                onClick={(e) => handleSort(e)}
                              />
                            </>
                          </span>
                        </th>
                        <th className="text-nowrap">
                          # Truck
                          <span className="ms-1 cursor-pointer">
                            <>
                              <img
                                src="images/sort-dark-up-arrow (1).svg"
                                id="truck_count@DESC"
                                alt="sort-arrow-up"
                                className="cust-sort-arrow-color"
                                hidden={
                                  sortHide === "truck_count@ASC" || ""
                                    ? false
                                    : true
                                }
                                onClick={(e) => handleSort(e)}
                              />
                            </>
                            <>
                              <img
                                src="images/sort-down-arrow-white.svg"
                                id="truck_count@ASC"
                                alt="sort-arrow-down"
                                className="cust-sort-arrow-color"
                                hidden={
                                  sortHide === "truck_count@ASC" || ""
                                    ? true
                                    : false
                                }
                                onClick={(e) => handleSort(e)}
                              />
                            </>
                          </span>
                        </th>

                        <th className="text-nowrap">
                          # Trailer
                          <span className="ms-1 cursor-pointer">
                            <>
                              <img
                                src="images/sort-dark-up-arrow (1).svg"
                                id="trailer_count@DESC"
                                alt="sort-arrow-up"
                                className="cust-sort-arrow-color"
                                hidden={
                                  sortHide === "trailer_count@ASC" || ""
                                    ? false
                                    : true
                                }
                                onClick={(e) => {
                                  handleSort(e);
                                }}
                              />
                            </>
                            <>
                              <img
                                src="images/sort-down-arrow-white.svg"
                                id="trailer_count@ASC"
                                alt="sort-arrow-down"
                                className="cust-sort-arrow-color"
                                hidden={
                                  sortHide === "trailer_count@ASC" || ""
                                    ? true
                                    : false
                                }
                                onClick={(e) => {
                                  handleSort(e);
                                }}
                              />
                            </>
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* PS_3PL_1.19*/}
                      {carrierResponse?.length > 0 ? (
                        bindCarrierGrid()
                      ) : noResults ? (
                        <NoResultsFound />
                      ) : notFound ? (
                        <NoDataFound />
                      ) : null}
                    </tbody>
                  </table>
                </div>
                <div className="mt-3 d-flex justify-content-between">
                  <p className="mb-0 font-14 font-regular color-grey-v1 ">
                    # of Records :
                    <span className="color-black-v1 font-semibold font-18 primary-header-v1">
                      {((carrierGridRequest.start_index / carrierGridRequest.page_size) + 1) *
                        carrierGridRequest.page_size <
                        totalCount
                        ? ` ${((carrierGridRequest.start_index / carrierGridRequest.page_size) + 1) *
                        carrierGridRequest.page_size
                        } `
                        : ` ${totalCount} `}{" "}
                    </span>
                    out of
                    <span className="color-black-v1 font-semibold font-18 primary-header-v1">
                      {` ${totalCount} `}
                    </span>
                  </p>
                  {totalCount > 1 ? (
                    <>
                      <PaginationComponent
                        viewCount={carrierGridRequest.page_size}
                        totalCount={totalCount}
                        onPageChange={handlePageClick}
                        currentPage={carrierGridRequest.start_index / carrierGridRequest.page_size}
                      />{" "}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CarrierInformation;
