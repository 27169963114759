import { createContext, useContext, useState, ReactNode, useEffect } from "react";
import React from "react";
import { Theme } from "../enums/enums";
import { createGlobalStyle } from 'styled-components';
import { Helmet, HelmetProvider } from "react-helmet-async";

//PS_24 Declaring data types of variables to be used.
interface ThemeProviderProps {
    themeType: Theme,
    children: ReactNode
}

interface IThemeContext {
    themeType: Theme,
    changeTheme?: (themeType: Theme) => void
}

//PS_26 Declaring default theme to variable to get the theme type and corresponding theme type.   
const defaultTheme: IThemeContext = {
    themeType: localStorage.getItem("theme") == Theme.DARK ? Theme.DARK : Theme.LIGHT,
}

//PS_27 creating context to use use theme across the application     
export const ThemeContext = createContext<IThemeContext>(defaultTheme);

//PS_28 Declaring theme provider
export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children, themeType }) => {
    //PS_29 Declaring variables to set the default time and theme variables accordingly.
    const [currentThemeType, setCurrentThemeType] = useState<Theme>(themeType);

    //PS_29,35 Change theme function is called to update the CurrentThemeType state variable.
    const changeTheme = (newTheme: Theme) => {
        //PS_36
        setCurrentThemeType(newTheme);
    }
    //PS_25 Declaring GlobalStyle to apply style across the application
    const GlobalStyle = createGlobalStyle`
body {
background-color: ${({ theme }) => currentThemeType == Theme.DARK ? '#242121 !important' : '#f9f9f9 !important'};
transition: background-color 0.3s ease;
`;

    //PS_30 Contains theme context and with current theme
    //changeTheme if there is any change in theme, 
    // Helmet provider to change the fav icon.
    // GlobalStyle method to style the body tag.
    return (
        <ThemeContext.Provider
            value={{
                themeType: currentThemeType,
                changeTheme
            }}
        >
            <HelmetProvider>
                <Helmet>
                    <link rel="icon" href={currentThemeType === Theme.DARK ? "/etm-icon-darktheme.ico" : "/ETM-ICON.ico"} />
                </Helmet>
                <GlobalStyle />
                {children}
            </HelmetProvider>
        </ThemeContext.Provider >
    )
}

// needed when we need to drill down the theme to child components
export const useTheme = () => useContext(ThemeContext);