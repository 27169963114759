import { TMSDetails } from "../../../interface/LoadInterface";

const ThirdPartyDetails: React.FC<{ tmsDetail: TMSDetails }> = ({ tmsDetail }) => {

   //PS_17 Default ThirdPartyDetails object with empty strings
    const defaultDetails: TMSDetails = {
        tms:"",
        name: "",
        email: "",
        contact: ""
    };

    //PS_18 Check if tmsDetail is null or has the same structure as defaultDetails
    const hasValidDetails = tmsDetail && JSON.stringify(tmsDetail) !== JSON.stringify(defaultDetails);
    //PS_19 - 20
    return (
        <>
            <div className="col-12 col-md-6 mb-3">
                <div className="content-container scroll-list-details h-100">
                    {hasValidDetails ?
                    <div className="h-100px">
                        <h3 className="font-18 font-semibold primary-text-color mb-3">{tmsDetail.tms||"-"} Details</h3>

                        <ul >
                        <li className="font-14 font-medium table-text-color mb-3">Contact Name:<span
                                className="font-14 font-semibold primary-text-color ms-1">{tmsDetail?.name || "-"} </span></li>
                            <li className="font-14 font-medium table-text-color mb-3">Contact Email:<span
                                className="font-14 font-semibold primary-text-color ms-1">{tmsDetail?.email || "-"}
                            </span></li>
                            <li className="font-14 font-medium table-text-color">Contact #:<span
                                className="font-14 font-semibold primary-text-color ms-1">{tmsDetail?.contact || "-"} </span></li>
                        </ul>
                        </div>:
                        (
                            <div className="h-100px">
                                <div className="d-flex flex-row justify-content-center align-items-center h-inherit">
                                    <span className="no-records-found small me-2"></span>
                                    <p className="font-13 font-semibold color-black-v1 m-0">No Data Found</p>
                                </div>
                            </div>
                        )}
                </div>
            </div>
        </>
    )
}

export default ThirdPartyDetails;