// PS_PR_1.0 -PS_PR_1.1 - importing the package.
import { useEffect, useState } from 'react';
import { RoleEnum } from '../../enums/enums';
import { parseToken } from '../../utils/utils';
import ProfilePasswordReset from '../UserOnboarding/ProfilePasswordReset';
import { SuccessToast } from '../Resuables/SuccessToast';
import TMSAccountInformation from './TMSAccountInformation';
import TMSConfigurationDetails from './TMSConfiguration';

const Profile = () => {
    // PS_PR_1.3-PS_PR_1.3-state variable
    let [userRole, setUserRole] = useState<string>('');
    let [openAcc, setAcc] = useState<boolean>(false);
    let [openConfig, setConfig] = useState<boolean>(false);
    let [openPass, setPass] = useState<boolean>(false);
    let [message, setMessage] = useState<string>('');
    let impersonate: string | null = ""
    let [openToastMessage, setToast] = useState<boolean>(false)
    const TMSID = localStorage.getItem('tmsId');
    let [tmsId, setUserId] = useState<string>(TMSID||'0')
    
    useEffect(() => {
        // PS_PR_1.3-PS_PR_1.5        // Get userRole from accessToken
        const token = localStorage.getItem('accessToken');
        if (token) {
            //PS_PR_1.7-PS_PR_1.10 -invoking the utlis method.
            const payload = parseToken(token);
            if (payload) {
                //PS_PR_1.11-PS_PR_1.17 - impersonate check
                if (payload.userRole === RoleEnum.ETM_ADMIN) {
                    impersonate = localStorage.getItem('isImpersonate');
                    if (impersonate == "true") {
                        setUserRole(RoleEnum.THIRD_PARTY_ADMIN)
                    } else {
                        setUserRole(payload.userRole);
                    }
                } else {
                    setUserRole(payload.userRole);
                }

            }
        }
        setAcc(true)

    }, []);
    useEffect(() => {
        if (openToastMessage) {
            const timer = setTimeout(() => {
                setToast(false); // Hide the toast message after 10 seconds
            }, 3000);

            return () => clearTimeout(timer); // Clear the timer on component unmount or when openToastMessage changes
        }
    }, [openToastMessage]);

    const handleAccount = () => {
        // PS_PR_1.19-PS_PR_1.19
        setAcc(true)
        setConfig(false)
        setPass(false)
    }
    const handleConfig = () => {
        setAcc(false)
        setConfig(true)
        setPass(false)

    }
    const handlePass = () => {
        setAcc(false)
        setConfig(false)
        setPass(true)
    }

    const OpenToast = (messages: string) => {
        setToast(true)
        setMessage(messages)
    }

    return (
        <>
            {openToastMessage && (SuccessToast(message))}
            <div className="col-md-12 fixedhd">
                <div className="m-4 py-4 mb-3 border-0 p-4 primary-shadow primary-bg-color rounded-3">
                    <h5 className="color-black-v1 font-22 mb-3 font-bold">Profile</h5>
                    <div className="d-none d-md-flex align-items-center justify-content-between gap-3 flex-wrap mb-4">
                        <ul
                            className="nav nav-tabs border-0 cust-tab-list"
                            id="myTab"
                            role="tablist"
                        >
                            <li className="nav-item cust-nav-item" role="presentation">
                                <button
                                    className="nav-link cus-tab-link cus-tab-border border-0 color-grey font-semibold font-14 active nav-bg"
                                    id="accountinfo-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#accountinfo"
                                    type="button"
                                    role="tab"
                                    aria-controls="accountinfo"
                                    aria-selected="true"
                                    onClick={handleAccount}
                                >
                                    Account Information
                                </button>
                            </li>
                            <li className="nav-item cust-nav-item" role="presentation">
                                <button
                                    className="nav-link cus-tab-link cus-tab-border border-0 color-grey font-semibold font-14 nav-bg"
                                    id="pwdreset-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#pwdreset"
                                    type="button"
                                    role="tab"
                                    aria-controls="pwdreset"
                                    aria-selected="false"
                                    onClick={handlePass}

                                >
                                    Password Reset

                                </button>
                            </li>
                            {(userRole === RoleEnum.THIRD_PARTY_ADMIN) && (
                                <li className="nav-item cust-nav-item" role="presentation">
                                    <button
                                        className="nav-link cus-tab-link cus-tab-border border-0 color-grey font-semibold font-14 nav-bg"
                                        id="configuration-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#zconfiguration"
                                        type="button"
                                        role="tab"
                                        aria-controls="configuration"
                                        aria-selected="false"
                                        onClick={handleConfig}

                                    >
                                        Configuration
                                    </button>
                                </li>
                            )
                            }
                        </ul>
                    </div>
                    {/* tabbed menu structure for laptop view ends here */}
                    {/* tabbed menu structure for mobile view starts here */}


                    <div className="tab-content" id="myTabContent">
                        {openAcc && <TMSAccountInformation userRole={userRole} tmsId={tmsId} openToast={OpenToast} ></TMSAccountInformation>}

                        {openConfig && <TMSConfigurationDetails tmsId={tmsId} openToast={OpenToast}></TMSConfigurationDetails>}

                        {openPass && <ProfilePasswordReset tmsId={tmsId} openToast={OpenToast}></ProfilePasswordReset>}

                    </div>
                </div>
            </div>

        </>
    )
}

export default Profile;