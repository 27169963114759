import { RoleEnum } from "../../enums/enums";
import { AuditResponse, AuditReportProps } from "../../interface/Interface";
import { getTimestampToDate } from "../../utils/Formatting";
import { userRole } from "../../utils/utils";
import NoDataFound from "../Resuables/NoDataFound";
import NoResultsFound from "../Resuables/NoResultsFound";
import Reports from "./Reports";

const AuditReports: React.FC<AuditReportProps> = ({
  Data,
  handleSortClick,
  renderSortArrow,
  NoResults,
  NoData,
  Groups,
}) => {

  function bindAuditReports() {
    return Data?.map((report, index) => (
      <>
        <tr>
          <td className="text-nowrap py-3 px-4">
            {report?.user_name}
            <p className="m-0 table-text-color pt-1">
              {userRole(report?.role)}
            </p>
          </td>
          {Groups &&
            Groups.length > 0 &&
            Groups[0] == RoleEnum.ETM_ADMIN &&
            localStorage.getItem("isImpersonate") != "true" ? (
            <td className="text-nowrap">{report?.tms_name}</td>
          ) : (
            <></>
          )}
          <td className="text-nowrap">{report?.tms_load_number}</td>
          <td className="text-nowrap">
            {getTimestampToDate(report?.timestamp_utc, 11)}
          </td>
          <td className="text-nowrap">{report?.log}</td>
        </tr>
      </>
    ));
  }

  return (
    <>
      <div className="table-responsive theme-table bg-white">
        <table className="table table-borderless mb-0">
          <thead>
            <tr>
              <th className="text-nowrap">
                Username
                <span
                  className="ms-1 cursor-pointer"
                  onClick={() => handleSortClick("user_name")}
                >
                  {renderSortArrow("user_name")}
                </span>
              </th>
              {Groups &&
                Groups.length > 0 &&
                Groups[0] == RoleEnum.ETM_ADMIN &&
                localStorage.getItem("isImpersonate") != "true" ? (
                <th className="text-nowrap">
                  Company Name
                  <span
                    className="ms-1 cursor-pointer"
                    onClick={() => handleSortClick("tms_name")}
                  >
                    {renderSortArrow("tms_name")}
                  </span>
                </th>
              ) : (
                <></>
              )}
              <th className="text-nowrap">
                Load ID
                <span
                  className="ms-1 cursor-pointer"
                  onClick={() => handleSortClick("tms_load_number")}
                >
                  {renderSortArrow("tms_load_number")}
                </span>
              </th>
              <th className="text-nowrap">
                Time Stamp
                <span
                  className="ms-1 cursor-pointer"
                  onClick={() => handleSortClick("timestamp_utc")}
                >
                  {renderSortArrow("timestamp_utc")}
                </span>
              </th>

              <th className="text-nowrap">Log</th>
            </tr>
          </thead>
          <tbody>
            {bindAuditReports()}
            {NoData ? <NoDataFound /> : <></>}
            {NoResults ? <NoResultsFound /> : <></>}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default AuditReports;
