import React, { useEffect, useState } from "react";
import { UpdateDriverDTO } from '../../../interface/Interface';
import { DriverDetail } from "../../../interface/LoadInterface";
import { updateDriver } from "../../../service/Api";
import { globalPhoneValidator } from "../../../utils/utils";

const DriverDetailPopup: React.FC<{ getLoads: any, loadId: number, driverDetail: DriverDetail, openPopup: () => void, openLoader: () => void, closeLoader: () => void, onError: () => void, openToast: any }> = ({ getLoads, loadId, driverDetail, openPopup, openLoader, closeLoader, onError, openToast }) => {
    //PS_3PL_1.0
    let updateDriverDTO: UpdateDriverDTO = {
        name: driverDetail.driver_name || "",
        driver_number: driverDetail.driver_number || "",
        country_code: "+1",
        tmsId: localStorage.getItem("tmsId") || "0",
        loadId: loadId,
    };
    const [updateDriverRequest, setUpdateDriverRequest] = useState<UpdateDriverDTO>(updateDriverDTO);
    let [error, setError] = useState({
        errName: "",
        errPhoneNumber: "",
    });

    /*When the user clicks on "esc" button in the 
   popup for editing Driver Details
   using eventListerner, when the key pressed is escape,
   onClose() function is called. */
    useEffect(() => {
        const handleEscape = (event: any) => {
            if (event.key === "Escape") {
                openPopup();
            }
        };

        document.addEventListener("keydown", handleEscape);

        return () => {
            document.removeEventListener("keydown", handleEscape);
        };
    });

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.name == "driver_number") {
            if (e.target.value.length < 16) {
                setUpdateDriverRequest({ ...updateDriverRequest, [e.target.name]: e.target.value });
            }
        } else {
            setUpdateDriverRequest({ ...updateDriverRequest, [e.target.name]: e.target.value });
        }
    }

    //PC_SI_18-PC_SI_21-Method to validate the input fields  
    const validateFields = () => {
        let isValid = true;
        const phonenumber = updateDriverRequest.driver_number;

        // Get the length of the string
        error = {
            errName: "",
            errPhoneNumber: "",
        };
        if (!updateDriverRequest.name.trim()) {
            error.errName = "Please enter a value";
            isValid = false;
        }
        // Validate the phone number
        if (!globalPhoneValidator(phonenumber)) {
            error.errPhoneNumber = "Please enter a valid phone number";
            isValid = false;
        }
        setError(error);
        return isValid;
    }

    const submit = async () => {
        try {
            openLoader()
            let isValid = validateFields();

            if (isValid) {
                openPopup()
                const response = await updateDriver(updateDriverRequest);
                //PS_3PL_1.8
                if (response.data.code == 200) {
                    openToast("Driver Updated Successfully")
                    getLoads()
                } else {
                    //PS_3PL_1.9
                    onError();
                }
            }
        } catch (e: any) {
            console.error(e);
            openPopup()
        } finally {
            closeLoader()
        }
    };


    return (
        <div className="modal fade show" id="driver-details" tabIndex={1} aria-labelledby="exampleModalLabel" aria-modal="true" role="dialog" style={{ display: "block", paddingLeft: "0px", backgroundColor: "rgba(0, 0, 0, 0.5)" }} onKeyDown={e => { if (e.key === "Enter") { submit() } }}>
            <div className="modal-dialog modal-dialog-centered ">
                <div className="modal-content secondary-bg-color p-2">
                    <div className="modal-header border-0">
                        <h1 className="modal-title fs-5 secondary-header" id="exampleModalLabel">Driver Details</h1>
                        <button type="button" className="btn-close close-icon" data-bs-dismiss="modal" aria-label="Close" onClick={openPopup}></button>
                    </div>
                    <div className="modal-body pt-2">
                        <div className="row">

                            <div className="col-md-12 mb-4">
                                <label className="font-semibold custom-label pb-2" htmlFor="Stop">Name <span className="required-color" >*</span></label>
                                <input type="text" className="form-control custom-form-input primary-bg-color primary-border-color" id="Stop" placeholder="Enter Name" name="name" value={updateDriverRequest.name} onChange={handleOnChange} />
                                <label className="required-color font-12 font-regular">{error.errName}</label>
                            </div>

                            <div className="col-md-12 mb-4">
                                <label className="font-semibold custom-label font-14 pb-2" htmlFor="PhoneNumber">Phone #
                                    <span className="required-color"> *</span></label>
                                <div className="input-group">
                                    <select className="form-select pe-3 custom-form-input custom-select-number primary-bg-color primary-border-color" aria-label="Default select example">
                                        <option selected>+1</option>
                                    </select>
                                    <input type="text" className="form-control custom-form-input primary-bg-color primary-border-color" id="PhoneNumber" placeholder="Enter Phone Number" name="driver_number" value={updateDriverRequest.driver_number != "" ? updateDriverRequest.driver_number : ""} onChange={handleOnChange} max="9999999999" />
                                </div>
                                <label className="required-color font-12 font-regular">{error.errPhoneNumber}</label>
                            </div>


                        </div>
                    </div>
                    <div className="modal-footer border-0 pt-0">
                        <button type="button" className="btn text-btn  font-14 font-medium px-4" data-bs-dismiss="modal" onClick={openPopup}>Cancel</button>
                        <button type="button" className="btn primary-btn font-14   font-medium px-4" data-bs-dismiss="modal" onClick={submit} onKeyDown={e => { if (e.key === "Enter") submit() }}>Save</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DriverDetailPopup;
