export function SomethingWentWrong(props: any) {
 
    return (
        <>  
            <div
                className="modal fade show"
                id="exampleModal"
                aria-labelledby="exampleModalLabel"
                aria-modal="true"
                role="dialog"
                tabIndex={-1} 
                aria-hidden="true"
                style={{ backgroundColor: "rgba(0, 0, 0, .5)",
                display: "block" }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content secondary-bg-color">
                        <div className="modal-body pt-4">
                            <div className="row text-center justify-content-center">
                                <img
                                    src="/images/alert-icon.svg"
                                    alt="alert-icon"
                                    className="alert-icon p-0"
                                />
                                {/* <h1 className="primary-header mt-3" id="exampleModalLabel">
                                Alert
                            </h1> */}
                                <p className="form-text-color font-16 font-regular mt-4">
                                    Oops! Something Went Wrong                  
                                </p>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-center pb-4 pt-0 border-0">
                            <button
                                type="button"
                                className="btn primary-btn font-semibold px-4"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => props.onClose()}
                            >
                                Ok
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}








