import { CarrierInfo } from "../../interface/LoadInterface";

const CarrierDetails: React.FC<{ carrier: CarrierInfo }> = ({ carrier }) => {
    //PS_01
    
    const defaultDetails: CarrierInfo = {
        carrier_name: "",
        contact_name: "",
        email_id: "",
        mc_number: "",
        carrier_id: "",
        contact_number: ""
    };
    //PS_02 Check if carrier is null or has the same structure as defaultDetails
    const hasValidDetails = carrier && JSON.stringify(carrier) !== JSON.stringify(defaultDetails);
    //PS_01
    return (
        <div className="col-12 col-lg-6 mb-3">
            <div className="content-container list-details h-100">
                <h3 className="font-18 font-semibold mb-4 primary-text-color">Carrier Details</h3>
                {hasValidDetails ? (
                    <ul className="d-flex flex-wrap">
                        <li className="font-14 font-medium table-text-color">Company Name: 
                        <span className="font-14 font-semibold primary-text-color ms-1"> {carrier?.carrier_name} </span>
                        </li>
                        <li className="font-14 font-medium table-text-color">Contact Name: 
                            <span className="font-14 font-semibold primary-text-color ms-1"> {carrier?.contact_name} </span>
                        </li>
                        <li className="font-14 font-medium table-text-color">Email Address: 
                            <span className="font-14 font-semibold primary-text-color ms-1"> {carrier?.email_id} </span>
                        </li>
                        <li className="font-14 font-medium table-text-color">Carrier ID: 
                            <span className="font-14 font-semibold primary-text-color ms-1">  {carrier?.carrier_id} </span>
                        </li>
                        <li className="font-14 font-medium table-text-color">MC #: 
                            <span className="font-14 font-semibold primary-text-color ms-1">{carrier?.mc_number} </span>
                        </li>
                        <li className="font-14 font-medium table-text-color">Contact #: 
                            <span className="font-14 font-semibold primary-text-color ms-1"> {carrier?.contact_number} </span>
                        </li>
                    </ul>
                ) : (
                    <div className="h-100px">
                    <div className="d-flex flex-row justify-content-center align-items-center h-inherit">
                        <span className="no-records-found small me-2"></span>
                        <p className="font-13 font-semibold color-black-v1 m-0">No Data Found</p>
                    </div>
                </div>
                )}
            </div>
        </div>
    );
}

export default CarrierDetails;